import { render, staticRenderFns } from "./pagos.vue?vue&type=template&id=29285bb4&scoped=true"
import script from "./pagos.vue?vue&type=script&lang=js"
export * from "./pagos.vue?vue&type=script&lang=js"
import style0 from "./pagos.vue?vue&type=style&index=0&id=29285bb4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29285bb4",
  null
  
)

export default component.exports