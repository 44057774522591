<template>
    <div>
        <!-- <DashboardNominas/> -->
        <template v-if="rol=='empleado'">
            <DashboardNominas/>
        </template>
        <template v-if="rol!='empleado'">
            <template
                v-if="rol == 'root'"
            >
                <DashboardNominas/>
            </template>
            <template v-else-if="rol == 'admin-depto'">
                <DashboardGDTH/>
            </template> 
            <template v-else>
                <template
                    v-if="tipoDashboard == 'gdth'"
                >
                    <DashboardGDTH/>
                </template>
                <template
                    v-else
                >
                    <DashboardNominas/>
                </template>

                
            </template>
            
        </template>
    </div>
</template>
<script>
import DashboardGDTH from '@/views/DashboardGDTH'
import DashboardNominas from '@/views/DashboardNominas'
export default {
    components: {
        DashboardGDTH,
        DashboardNominas
    },
    data() {
        return {
            tipoDashboard : 'payroll',
            datosLogin: null,
            rol: null,
        }
    },
    methods: {
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.tipoDashboard = this.datosLogin.tipo_dashboard;
    },
}
</script>
<style scoped>

</style>