<template>
    <div>
        <v-col cols="12" xs="12" sm="12" md="12" class="pa-0" v-if="cardPercepciones">
            <v-card class="borde-card big elevation-0">
                <v-card-text class="pr-5"  style="min-height:27.5vh;">
                    <v-row>
                        <!--Titulo del widget-->
                        <v-col cols="12" class="d-flex px-0">
                            <v-col cols="10" class="pa-0">
                                <p class="widget-title">Percepciones</p>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                            <v-btn small icon class="float-right">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            small 
                                            icon 
                                            class="float-right"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeVMenuCss(false)"
                                        >
                                            <v-icon >more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, i) in [{title:'Mover'},{title:'Quitar'}]"
                                            :key="i"
                                        >
                                            <v-list-item-title @click="cardAction(item.title,4)" >{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-btn>
                            </v-col>
                        </v-col>
                        <!--Titulo del widget-->

                        <!--Percepciones-->
                        <v-col cols="12" xs="12" sm="12" md="12" class="d-flex pa-0">
                            <v-row>
                                <v-col cols="12" class="pr-9" style=" z-index:2;">
                                    <v-chart
                                        style="width:100%; min-height:400px; position:relative; margin-bottom:0px"
                                        :options="percepciones"
                                        :autoresize="true"
                                    />
                                </v-col>
                            </v-row>
                        </v-col>
                        <!--Percepciones-->

                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapActions, mapState } from "vuex";
import userApi from "@/api/usuarios";
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import dashboardApi from "@/api/dashboard";
import draggable from "vuedraggable";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';

export default {
	components: {
        VuetifyDatePicker,
        draggable,
    },
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
			cardColaboradores : true,
			cardContratos : true,
			cardCostolaboral : true,
			cardPercepciones : true,
			cardPagos : true,
			datosLogin	:'',
			rol			:'',
			show1		: false,
			show2		: false,
			usuario		: {
				id: "",
				nombre: "",
				apellido_paterno	: "",
				apellido_materno	: "",
				puesto				: "",
				email				: "",
				fecha_nacimiento	: "",
				telefono			: "",
				celular				: "",
				password			: "",
				primer_inicio_sesion: "",
				rol_id				: "",
			},
			min_fecha : null,
			max_fecha : null,
            fecha_inicio : null,
            fecha_fin : null,
			dialog_grafica : false,
			mostrar_grafica : false,
			
			percepciones: {
				tooltip: {
					trigger: 'axis'
				},
				 legend: {
                    icon    : "circle",
                    orient  : "vertical",
                    type    : "scroll",
					right:110,
					height:250,
					whidth:150,
					top:100,
					itemGap:20,
                    textStyle: {
                        fontSize    : 14,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#57575C",
                    },
					data: ['Sueldo bruto', 'Comisiones', 'Aguinaldo', 'Vacaciones', 'Horas extras dobles', 'Horas extras triples']
                },
				grid: {
					right: '330px',
					left: '8%',
					bottom: '8%',
					top:'8%'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24']
				},
				yAxis: {
					type: 'value'
				},
				dataZoom: [
					{
						type: 'inside'
					}
				],
				series: [
					{
						name: 'Sueldo bruto',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					},
					{
						name: 'Comisiones',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					},
					{
						name: 'Aguinaldo',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					},
					{
						name: 'Vacaciones',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					},
					{
						name: 'Horas extras dobles',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					},
					{   
						name: 'Horas extras triples',
						type: 'line',
						stack: '总量',
						data: [158350, 162543, 148900, 158934, 140900, 158567, 158350, 162543]
					}
				]
            },
			
		}
	},
	computed: {
    	...mapState("auth", ["datos", "user"]),
	},
    watch:{
        father_fecha_inicio:function(newValue, oldValue){
            this.inicio()
        },
        father_fecha_fin:function(newValue, oldValue){
            this.inicio()
        }
    },
	methods:{
        onUnpublishedChange({ added }) {
            console.log(added)
            },
            onEnd(sd){
                
                console.log(sd);
                console.log(this.list1);
                console.log(this.list2);
            },
		...mapActions("auth", ["logout", "update_password", "update_cliente"]),
		inicio(){
			if (this.datos.primer_inicio_sesion == true) {
				this.primer_login = true;
				this.dialog_primer_login = true;
				setTimeout(function() {
					document.getElementById("nuevo_password").focus();
				}, 250);
			}

			let empleado_id = null;
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

            let date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            let mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();
            this.min_fecha = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            this.max_fecha =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

			let parametrosDashboard = {};
            if (this.rol != "admin-depto") {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            } 
            else {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    fecha_inicio: inicio,
                    fecha_final : final,
                    rol_name    : this.rol,
                };
            }

			if (this.rol != "root") {
                empleadosApi.getDashboardNominas(parametrosDashboard).then(async(response) => {
                    
                    
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
		
		},
        changeVMenuCss(bool){
            if(bool){
                setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.add("drop");
                    }
                },50);
                
            }else{
                 setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.remove("drop");
                    }
                },200);
            }
        },
		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						this.cardColaboradores = false;
						break;
					case 2:
						this.cardContratos = false;
						break;
					case 3:
						this.cardCostolaboral = false;
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						this.cardPagos = false;
						break;	
					case 'restaurar':
						this.cardColaboradores = true;
						this.cardContratos = true;
						this.cardCostolaboral = true;
						this.cardPercepciones = true;
						this.cardPagos = true;
						break;
				}
					
				
			}else if(type == "Mover"){

			}
		},
		async update() {
			this.llena_datos();
			await this.update_password(this.user_vuex);
			await userApi.updatePassword(this.usuario).then((response) => {
					userApi.getUsuario(this.usuario.id).then((response) => this.update_cliente(response))
					.catch((err) => console.log(err));
					this.cerrarModal();
					this.respuesta_ok("cliker® ya esta listo.");
				})
				.catch((err) => this.respuesta_error(err));
		},
		verificar() {
			this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
					if (this.confirmacion == this.nuevo_password) {
						this.password_igual = false;
						this.update();
					} 
					else {
						this.password_igual = true;
					}
				}
			});
		},
		llena_datos() {
			this.usuario.id 				= this.datos.id;
			this.usuario.nombre 			= this.datos.nombre;
			this.usuario.apellido_paterno 	= this.datos.apellido_paterno;
			this.usuario.apellido_materno 	= this.datos.apellido_materno;
			this.usuario.puesto 			= this.datos.puesto;
			this.usuario.email 				= this.datos.email;
			this.usuario.telefono 			= this.datos.telefono;
			this.usuario.celular 			= this.datos.celular;
			this.rol_nombre 				= this.datos.rol.display_name;
			this.usuario.rol_id				= this.datos.rol.id;
			this.usuario.primer_inicio_sesion = false;
			this.usuario.password 			= this.confirmacion;
			this.user_vuex.email 			= this.usuario.email;
			this.user_vuex.password 		= this.confirmacion;
			this.usuario.fecha_nacimiento 	= "";

			if (this.datos.fecha_nacimiento != null) {
				this.usuario.fecha_nacimiento = this.datos.fecha_nacimiento.replace(
				/^(\d{4})-(\d{2})-(\d{2})$/g,
				"$3/$2/$1"
				);
			}
		},
		cerrarModal() {
			this.dialog_grafica = false;
			this.dialog_primer_login = false;
			this.primer_login = false;
			this.usuario = {};
		},
		respuesta_error(err) {

			/* if(typeof err.response.data.error === 'string'){
				Notify.ErrorToast(err.response.data.error);
			}else{
				let error = Object.values(err.response.data.error);
				let leyenda = ``;

				for (var i = 0; i < error.length; i++) {
					leyenda+= `* ` + error[i] + `\n`;
				}

				Notify.ErrorToast(leyenda);
			} */
			Notify.Alert("Error", err.response.data.error);
		},
		respuesta_ok(msj = "") {
			this.cerrarModal();
			this.dialog = false;
			Notify.Success("Operación exitosa", msj);
		},

		//GRAFICAS
		mouseover_rotacion01(event, chartContext, config) {
            this.percepciones.series[0].label.normal.show = false;
        },
        mouseout_rotacion01(event, chartContext, config) {
            this.percepciones.series[0].label.normal.show = true;
        },
		mouseover_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = false;
        },
        mouseout_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = true;
        },
		mouseover_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = false;
        },
        mouseout_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = true;
        },
		mouseover_nuevosIngresos01(event, chartContext, config) {
            this.nuevosIngresos.series[0].label.normal.show = false;
        },
        mouseout_nuevosIngresos01(event, chartContext, config) {
            this.nuevosIngresos.series[0].label.normal.show = true;
        },
		onClickRotacion(event, instance, ECharts) {
            if (arguments[0].data.name != "Sin rotación") {
                this.finaliza_carga_grafica = false;
                let parametros = null;
                if (this.rol == "admin") {
                    parametros = {
                        rol_name: this.datosLogin.rol.name,
                        usuario_id: this.datosLogin.id,
                        empresa_id: arguments[0].data.id,
                        rol_name: "admin-empresa",
                        min_fecha: this.min_fecha,
                        max_fecha: this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
						debugger;
                        let format_rotacion = response;
						if (format_rotacion.length == 0 || format_rotacion == undefined) {
							for(let i=0;i<2;i++){
								let b = { value: (i+1), name: "Sin rotación skjdf ldk fn"+i,id: (i+1) };
								this.rotacion_template.series[0].data.push(b);
							}
						}else{
							format_rotacion.forEach((element) => {
								let b = {
									value: element.length,
									name: element[0].nombre,
									id: element[0].id,
								};
								this.rotacion_template.series[0].data.push(b);
							});
						}
                        //this.rotacion_template.title.text = `Empresa: ${arguments[0].data.name}`;
                        this.rotacion_template.title.text = `Ingresos por sucursal`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                } 
                else if (this.rol == "admin-empresa") {
                    parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        sucursal_id : arguments[0].data.id,
                        rol_name    : "admin-sucursal",
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
                        let format_rotacion = response;
                        format_rotacion.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.rotacion_template.series[0].data.push(b);
                        });
                        //this.rotacion_template.title.text = `Sucursal: ${arguments[0].data.name}`;
                        this.rotacion_template.title.text = `Ingresos por departamento`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                }
            }
        },
		onClickRotacionTemplate() {
            if (this.rol == "admin") {
                this.rotacion_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    sucursal_id : arguments[0].data.id,
                    rol_name    : "admin-sucursal",
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                };
                dashboardApi.getDashboardRotacion(parametros).then((response) => {
                    let format_rotacion = response;
                    format_rotacion.forEach((element) => {
                        let b = {
                            value: element.length,
                            name: element[0].nombre,
                            id: element[0].id,
                        };
                        this.rotacion_template2.series[0].data.push(b);
                    });
                    //this.rotacion_template2.title.text = "Sucursal: " + arguments[0].data.name;
                    this.rotacion_template2.title.text = `Ingresos por departamento`;
                    this.mostrar_grafica = true;
                })
                .catch((err) => console.log(err));
            }
        },
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	}, 
	mounted() {
		setTimeout(()=>{
		this.overlay = false;
		},1000);
	},
}
</script>

<style scoped>
.accionBtns{
    margin-top:-20px;
    padding-right:25px;
}

.btnDashboard.theme--light.v-btn {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    text-transform: capitalize;
	transition: 0.3s;
	height: 38px !important;
	background: #58C5D8;
	border-radius: 10px;
}

.btnDashboard.theme--light.v-btn:hover {
    background: #2DB9D1 !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
	-webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
	-moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
}
	.blue-divider{
		opacity: 0.7;
		border: 1px solid #2DB9D1;
	}
	.blue-divider-mute{
		border: 1px solid rgba(45, 185, 209, 0.1);
	}
	.colab-sub-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #636997;
	}
	.colab-total{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.colab-total-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.contrato-renovar{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #636997;
	}
	.contrato-renovar-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 800;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.contrato-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #636997;
	}
	.contrato-title-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 800;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #636997;
	}
	.costo-lab{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		line-height: 13px;
		color: #444975;
	}
	.costo-lab-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.widget-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.center{
		padding-left:32px;
		padding-right:32px;
		margin-left: auto;
		margin-right: auto;
	}
	.margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}
	.backgroundDash{
		height: 80%;
		width: 80%;
		margin:5%;
		padding: 5%;
		position: fixed;
		background: url("/static/bg/cliker.png");
		background-repeat: no-repeat;
		background-size:50%;
		background-position: center center;
	}
	#span_cambio {
		font-family: "Montserrat";
		font-style: normal;
		font-size: 11px;
		color: #FF5454;
		font-weight: bold;		
	}
	.modal-button {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.2px;
		text-decoration: none !important;

		color: #a0a4a8;
		outline: 0;
	}
	.modal-button.save {
		background: transparent !important;
		box-shadow: none;
		color: #007eff;
		margin-right: 30px;
		text-transform: none !important;
	}
	.padding-x50{
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
    .v-menu__content{
        border-radius: 15px;
        margin-top: 28px;
		width: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

	.drop{
        margin-top: 48px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
</style>