<template>
    <div>
        <v-col cols="12" xs="12" sm="12" md="12" class="pa-0" v-if="vuexShowCardCostoSocial">
            <v-card class="borde-card big elevation-0">
                <v-card-text class="pr-5" style="min-height:20vh;">
                    <v-row>
                        <!--Titulo del widget-->
                        <v-col cols="12" class="d-flex px-0">
                            <v-col cols="10" class="pa-0">
                                <p class="widget-title">Costo Social</p>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                            <v-btn small icon class="float-right">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            small 
                                            icon 
                                            class="float-right"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeVMenuCss(false)"
                                        >
                                            <v-icon >more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, i) in [{title:'Quitar'}]"
                                            :key="i"
                                        >
                                            <v-list-item-title @click="cardAction(item.title,3)" >{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-btn>
                            </v-col>
                        </v-col>
                        <!--Titulo del widget-->

                        <!--Costo laboral-->
                        <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                            <v-row>
                                <v-col cols="4" xs="4" sm="4" md="4" class="px-0">
                                    <img src="static/icon/dashboard/costo_laboral.png" class="col-sm-7 py-0"/>
                                </v-col>
                                <v-col cols="8" xs="8" sm="8" md="8" class="pa-0 pr-4">
                                    <v-row class="mb-1">
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="costo-lab">Concepto</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right costo-lab">Cantidad</p>
                                        </v-col>

                                        <v-col ols="12" xs="12" sm="12" md="12" class="mt-n5 pb-1">
                                            <v-divider class="blue-divider-mute"></v-divider>
                                        </v-col>
                                        
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">ISN</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.isn}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">IMSS Patrón</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.imssPatron}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">IMSS Colaborador</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.imssColaborador}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">RCV Patrón</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.rvcPatron}}</p>
                                        </v-col>
										<v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">RCV Colaborador</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.rcvColaborador}}</p>
                                        </v-col>
										<v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">INFONAVIT</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">${{costoSocial.infonavit}}</p>
                                        </v-col>
										<v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title">Impuesto Federal</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right contrato-title-num">${{costoSocial.impuestoFederal}}</p>
                                        </v-col>

                                        <v-col ols="12" xs="12" sm="12" md="12" class="mt-n5 pb-1 pl-n2">
                                            <v-divider class="blue-divider-mute"></v-divider>
                                        </v-col>

                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="colab-total">Total</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right colab-total-num mb-0">${{totalCostoSocial}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!--Costo laboral-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import { mapState, mapActions} from "vuex";

export default {
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
			cardColaboradores 	: true,
			cardContratos 		: true,
			cardCostolaboral 	: true,
			cardPercepciones 	: true,
			cardPagos 			: true,
			datosLogin			: '',
			rol					: '',
			cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
			costoSocial			:{
				costoSocial:"0.00",
				isn: "0.00",
				imssPatron: "0.00",
				imssColaborador: "0.00",
				rvcPatron: "0.00",
				rcvColaborador: "0.00",
				infonavit: "0.00",
				impuestoFederal: "0.00"
			},
			totalCostoSocial: "0.00",
		}
	},
    computed: {
        ...mapState("dashboard", ["dashboardCostoSocial", "dashboardTotalCostoSocial", "vuexShowCardCostoSocial"])
	},
    watch:{
        father_fecha_inicio:function(newValue, oldValue){
            this.inicio()
        },
        father_fecha_fin:function(newValue, oldValue){
            this.inicio()
        },

        dashboardCostoSocial:function(newValue, oldValue){
            this.costoSocial = newValue
        },
        dashboardTotalCostoSocial:function(newValue, oldValue){
            this.totalCostoSocial = newValue
        },
        vuexShowCardCostoSocial:function(newVal, oldValue){
            this.cardCostolaboral = newVal;
        }

    },
	methods:{
        ...mapActions("dashboard", ['setVuexShowCardCostoSocial']),
		inicio(){
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

			let parametrosDashboard = {};
            if (this.rol != "admin-depto") {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            } 
            else {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            }

			if (this.rol != "root") {
                /* empleadosApi.getDashboardNominas(parametrosDashboard).then(async(response) => {
                    this.costoSocial = response.percepciones.costosocial;
					this.totalCostoSocial = response.percepciones.totalCostosocial;
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                }); */
            }
		
		},
        changeVMenuCss(bool){
            if(bool){
                setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.add("drop");
                    }
                },50);
                
            }else{
                 setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.remove("drop");
                    }
                },200);
            }
        },
		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						this.cardColaboradores = false;
						break;
					case 2:
						this.cardContratos = false;
						break;
					case 3:
						//this.cardCostolaboral = false;
                        this.setVuexShowCardCostoSocial(false)
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						this.cardPagos = false;
						break;	
					case 'restaurar':
						this.cardColaboradores = true;
						this.cardContratos = true;
						this.cardCostolaboral = true;
						this.cardPercepciones = true;
						this.cardPagos = true;
						break;
				}
			}else if(type == "Mover"){

			}
		},
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	},
}
</script>

<style scoped>
	.blue-divider-mute{
		border: 1px solid rgba(45, 185, 209, 0.1);
	}
	.colab-total{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.colab-total-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.contrato-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #828282;
	}
	.contrato-title-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.costo-lab{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
		color: #BDBDBD;
	}
	.drop{
        margin-top: 48px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
	.v-menu__content{
        border-radius: 15px;
        margin-top: 28px;
		width: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
	.widget-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #444975;
	}
</style>