<template>
    <div>
       <v-col cols="12" xs="12" sm="12" md="12" class="pa-0" v-if="vuexShowCardPagos">
            <v-card class="borde-card big elevation-0">
                <v-card-text class="pr-5" style="min-height:27.5vh;">
                    <v-row>
                        <!--Titulo del widget-->
                        <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                            <v-col cols="10" class="pa-0">
                                <p class="widget-title">Pagos</p>
                            </v-col>
                            <v-col cols="2" class="pa-0">
                            <v-btn small icon class="float-right">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            small 
                                            icon 
                                            class="float-right"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeVMenuCss(false)"
                                        >
                                            <v-icon >more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, i) in [{title:'Quitar'}]"
                                            :key="i"
                                        >
                                            <v-list-item-title @click="cardAction(item.title,5)" >{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-btn>
                            </v-col>
                        </v-col>
                        <!--Fin Titulo del widget-->

                        <!--Percepciones-->
                        <template v-if="showDatos">
                            <v-col cols="12" xs="12" sm="7" md="8" lg="9" xl="9" class="d-flex pa-0">
                                <v-row>
                                    <v-col cols="12" class="pr-9" style=" z-index:2;">
                                        <v-chart
                                            style="width:100%; min-height:400px; position:relative; margin-bottom:0px"
                                            :options="pagos"
                                            :autoresize="true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="4" lg="3" xl="3">
                                <v-row>
                                    <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                        <p class="costo-lab">Concepto</p>
                                    </v-col>
                                    <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                        <p class="float-right costo-lab">Cantidad</p>
                                    </v-col>

                                    <template v-for="(concepto,index) in pagosList">
                                        <v-col :key="'a'+index" cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="concepto-title mb-1">{{concepto.nombre}}</p>
                                        </v-col>
                                        <v-col :key="'b'+index" cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right concepto-title-num mb-1">${{concepto.monto}}</p>
                                        </v-col>
                                    </template>

                                    <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                        <p class="concepto-total">Total</p>
                                    </v-col>
                                    <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                        <p class="float-right concepto-total-num mb-0">${{total}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </template>
                        <template v-if="!showDatos">
                            <v-row class="pa-0 ma-0" style="min-height:20vh;">
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="d-flex justify-center align-center text-center">
                                    <div>
                                        <p class="text-no-elementos mb-10">No hay elementos para mostrar</p>
                                    </div>
                                </v-col>
                            </v-row>
                            
                        </template>
                        <!--Fin Percepciones-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import pagos from './pagos';
import { mapState, mapActions } from "vuex";

export default {
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
			cardColaboradores   : true,
			cardContratos       : true,
			cardCostolaboral    : true,
			cardPercepciones    : true,
			cardPagos           : true,
			datosLogin	        :'',
			rol			        :'',
            cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
            total               : "0.00",
            showDatos           : false,
            pagosList           : [],
			pagos,
            
		}
	},
    computed: {
        ...mapState("dashboard", [
            "vuexPagosAxisX",
            "vuexPagosSeries",
            "vuexPagoList",
            "vuexPagosTotal",
            "vuexPagosShow",
            "vuexShowCardPagos"
        ])
	},
    watch:{
        father_fecha_inicio:function(newValue, oldValue){
            this.inicio()
        },
        father_fecha_fin:function(newValue, oldValue){
            this.inicio()
        },
        vuexPagosAxisX:function(newVal, oldVal){
            this.pagos.xAxis.data = newVal
        },
        vuexPagosSeries:function(newVal, oldVal){
            this.pagos.series[0].data = newVal
        },
        vuexPagoList:function(newVal, oldVal){
            //console.log("entre aqui -pagos",newVal)
            this.pagosList = newVal
        },
        vuexPagosTotal:function(newVal, oldVal){
            this.total =  newVal
        },
        vuexPagosShow:function(newVal, oldVal){
            this.showDatos = newVal
        },
        vuexShowCardPagos:function(newVal, oldVal){
            this.cardPagos = newVal
        }
    },
	methods:{
        ...mapActions("dashboard", ['setVuexShowCardPagos']),
		inicio(){
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

			let parametrosDashboard = {};
            if (this.rol != "admin-depto") {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            } 
            else {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            }
            
            /* this.pagos.xAxis.data = [] //nombres
            this.pagos.series[0].data = [] //datos
            this.pagosList = []
            this.total = "0.00"
            this.showDatos = false
			if (this.rol != "root") {
                empleadosApi.getDashboardNominas(parametrosDashboard).then(async(response) => {
                    this.pagos.xAxis.data = [] //nombres
                    this.pagos.series[0].data = [] //datos
                    this.pagosList = []
                    this.total = "0.00"
                    if(response.percepciones.percepciones.length>0){
                        this.showDatos = true
                        this.total = response.percepciones.totalPercepcion;
                        await response.percepciones.percepciones.forEach(element => {
                            if(parseFloat(element.monto)>0){
                                //this.pagosList.push(element)
                                this.pagos.xAxis.data.push(element.nombre)
                                this.pagos.series[0].data.push(parseFloat(element.monto.replace(',', '')))
                            }
                        });
                    }
                    else{
                        this.showDatos = false
                    }
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            } */
		
		},
        changeVMenuCss(bool){
            if(bool){
                setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.add("drop");
                    }
                },50);
                
            }else{
                 setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.remove("drop");
                    }
                },200);
            }
        },
		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						this.cardColaboradores = false;
						break;
					case 2:
						this.cardContratos = false;
						break;
					case 3:
						this.cardCostolaboral = false;
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						//this.cardPagos = false;
                        this.setVuexShowCardPagos(false)
						break;	
					case 'restaurar':
						this.cardColaboradores = true;
						this.cardContratos = true;
						this.cardCostolaboral = true;
						this.cardPercepciones = true;
						this.cardPagos = true;
						break;
				}
					
				
			}else if(type == "Mover"){

			}
		},
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	},
}
</script>

<style scoped>
	.widget-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #444975;
	}
    .v-menu__content{
        border-radius: 15px;
        margin-top: 28px;
		width: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

	.drop{
        margin-top: 48px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    .costo-lab{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
		color: #BDBDBD;
	}
    .concepto-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #828282;
	}
	.concepto-title-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
    .concepto-total{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.concepto-total-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
    .text-no-elementos{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }
</style>