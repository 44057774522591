<template>
    <div id="pageDashboard" class="mr-5 ml-6">
        <v-container class="backgroundDash" v-if="rol == 'root'">
            <div></div>
        </v-container>
        <v-container v-else grid-list-xl fluid @comportamiento="obtenvalor($event)">
            <v-row wrap>
                <v-row class="d-flex align-center justify-center " v-if="cargaGrafica == false">
                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex align-center justify-center " style="width: 80vw; height: 80vh;">
                        <div >
                            <div>
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="blue"
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <template>
                    <div v-show="cargaGrafica" class="div-cargar">
                        <template v-if="rol != 'admin-depto' && incidenciasShow">
                            <v-col cols="12" xs="12" sm="12" :md="displayGraficas" :lg="displayGraficas" :xl="displayGraficas" v-if="rol != 'empleado'">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pl-8 pt-7 pb-0">
                                        <i class="material-icons-outlined icono">beenhere </i>
                                        <div class="leyenda col-10 py-0" for="">
                                            Incidencias
                                        </div>
                                        <br>
                                        <div class="leyendaPeriodo col-12 pr-0 pl-9 py-0">
                                            Mes actual
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="pa-1 pt-0 px-5">
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-if="showIncidencias"
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pastel"
                                                :autoresize="true"
                                                @click="onClickIncidencia"
                                                @mouseover="mouseover_incidencia01"
                                                @mouseout="mouseout_incidencia01"
                                            />
                                        </div>
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-else
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pastelSinDatos"
                                                :autoresize="true"
                                            />
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <template v-else-if="rol == 'admin-depto' && incidenciasShow">
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="rol != 'empleado'">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pl-8 pt-7 pb-0">
                                        <i class="material-icons-outlined icono">beenhere </i>
                                        <div class="leyenda col-10 py-0" for="">
                                            Incidencias
                                        </div>
                                        <div class="leyendaPeriodo col-12 pr-0 pl-9 py-0">
                                            Mes actual
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="pa-1 pt-0 px-5">
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:500px; width:100%; margin-bottom: 20px;"
                                            v-if="showIncidencias"
                                        >
                                            <v-chart
                                                style="width:100%; height:500px; position:relative;"
                                                :options="pastel"
                                                :autoresize="true"
                                                @click="onClickIncidencia"
                                                @mouseover="mouseover_incidencia01"
                                                @mouseout="mouseout_incidencia01"
                                            />
                                        </div>
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-else
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pastelSinDatos"
                                                :autoresize="true"
                                            />
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <template>
                            <v-row justify-center>
                                <v-dialog
                                    v-model="dialog_grafica_incidencia"
                                    persistent
                                    max-width="500px"
                                    scrollable
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                        v-on="on"
                                        slot="activator"
                                        class="botonModal"
                                        id="btnModal"
                                        ></v-btn>
                                    </template>
                                    <perfect-scrollbar style="background: #fff">
                                        <v-card elevation="0" class="forma">
                                            <div>
                                                <v-btn
                                                    @click="cerrarModal()"
                                                    small
                                                    slot="activator"
                                                    icon
                                                    text
                                                    class="v-btn-cerrar float-right mr-3 mt-3"
                                                >
                                                    <v-icon class="icono-cerrar"></v-icon>
                                                </v-btn>
                                            </div>
                                            <v-card-text>
                                                <div
                                                    id="padre"
                                                    v-show="!finaliza_carga_grafica_incidencias"
                                                >
                                                    <div id="loading">
                                                        <v-progress-circular
                                                            indeterminate
                                                            :size="100"
                                                            :width="7"
                                                            color="blue"
                                                        ></v-progress-circular>
                                                    </div>
                                                </div>
                                                <v-container
                                                    v-show="finaliza_carga_grafica_incidencias"
                                                    grid-list-md
                                                >
                                                    <v-row wrap>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="rol != 'empleado'"
                                                        >
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%; padding:0px;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="incidencia_template"
                                                                    :autoresize="true"
                                                                    @click="onClickIncidenciaTemplate"
                                                                    @mouseover="mouseover_incidencia02"
                                                                    @mouseout="mouseout_incidencia02"
                                                                />
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="mostrar_grafica_incidencia"
                                                        >
                                                            <v-divider
                                                                :inset="inset"
                                                                class="espacio"
                                                            ></v-divider>
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="incidencia_template2"
                                                                    :autoresize="true"
                                                                    @click="onClickIncidenciaTemplate2"
                                                                    @mouseover="mouseover_incidencia03"
                                                                    @mouseout="mouseout_incidencia03"
                                                                />
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="mostrar_grafica_incidencia2"
                                                        >
                                                            <v-divider
                                                                :inset="inset"
                                                                class="espacio"
                                                            ></v-divider>
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="incidencia_template3"
                                                                    :autoresize="true"
                                                                    @mouseover="mouseover_incidencia04"
                                                                    @mouseout="mouseout_incidencia04"
                                                                />
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </perfect-scrollbar>
                                </v-dialog>
                            </v-row>
                        </template>

                        <template
                            v-if="
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal' && rotacionShow
                            "
                        >
                            <v-col cols="12" xs="12" sm="12" :md="displayGraficas" :lg="displayGraficas" :xl="displayGraficas">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pl-8 pt-7 pb-0">
                                        <i class="material-icons icono">refresh</i>
                                        <div class="leyenda col-10 py-0" for="">
                                            Rotación de empleados
                                        </div>
                                        <div class="leyendaPeriodo col-12 pr-0 pl-9 py-0">
                                            Últimos 3 meses
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="pa-1 pt-0 px-5">
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-if="showRotacion"
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative; margin-bottom:50px"
                                                :options="rotacion"
                                                :autoresize="true"
                                                @click="onClickRotacion"
                                                @mouseover="mouseover_rotacion01"
                                                @mouseout="mouseout_rotacion01"
                                            />
                                        </div>
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-else
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pastelSinDatos"
                                                :autoresize="true"
                                            />
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <template>
                            <v-row justify-center>
                                <v-dialog v-model="dialog_grafica" persistent max-width="500px" scrollable>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            slot="activator"
                                            class="botonModal"
                                            id="btnModal"
                                        ></v-btn>
                                    </template>
                                    <perfect-scrollbar style="background: #fff">
                                        <v-card elevation="0" class="borde-card">
                                            <div>
                                                <v-btn
                                                    @click="cerrarModal()"
                                                    small
                                                    slot="activator"
                                                    icon
                                                    text
                                                    class="v-btn-cerrar float-right mr-3 mt-3"
                                                >
                                                    <v-icon class="icono-cerrar"></v-icon>
                                                </v-btn>
                                            </div>
                                            <v-card-text>
                                                <div id="padre" v-show="!finaliza_carga_grafica">
                                                    <div id="loading">
                                                        <v-progress-circular
                                                            indeterminate
                                                            :size="100"
                                                            :width="7"
                                                            color="blue"
                                                        ></v-progress-circular>
                                                    </div>
                                                </div>
                                                <v-container v-show="finaliza_carga_grafica" grid-list-md>
                                                    <v-row wrap>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="rol != 'empleado'"
                                                        >
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%; padding:0px;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="rotacion_template"
                                                                    :autoresize="true"
                                                                    @click="onClickRotacionTemplate"
                                                                    @mouseover="mouseover_rotacion02"
                                                                    @mouseout="mouseout_rotacion02"
                                                                />
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="mostrar_grafica">
                                                            <v-divider
                                                                :inset="inset"
                                                                class="espacio"
                                                            ></v-divider>
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="rotacion_template2"
                                                                    :autoresize="true"
                                                                    @mouseover="mouseover_rotacion03"
                                                                    @mouseout="mouseout_rotacion03"
                                                                />
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </perfect-scrollbar>
                                </v-dialog>
                            </v-row>
                        </template>

                        <!-- Grafica principal de retardos en dashboard -->
                        <template
                            v-if="
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal' && retardosShow
                            "
                        >
                            <v-col cols="12" xs="12" sm="12" :md="displayGraficas" :lg="displayGraficas" :xl="displayGraficas" v-if="rol != 'empleado'">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pl-8 pt-7 pb-0">
                                        <i class="material-icons-outlined icono">alarm</i>
                                        <div class="leyenda col-10 py-0" for="">
                                            Retardos
                                        </div>
                                        <div class="leyendaPeriodo col-12 pr-0 pl-9 py-0">
                                            Mes actual
                                        </div>
                                    </v-card-title>
                                    <v-card-text class="pa-1 pt-0 px-5">
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-if="showRetardos"
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="retardos"
                                                :autoresize="true"
                                                @click="onClickRetardo"
                                                @mouseover="mouseover_retardo01"
                                                @mouseout="mouseout_retardo01"
                                            />
                                        </div>
                                        <div
                                            slot="widget-content"
                                            id="chartContainer"
                                            style="height:300px; width:100%; margin-bottom: 20px;"
                                            v-else
                                        >
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pastelSinDatos"
                                                :autoresize="true"
                                            />
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <!-- Template de graficas de retardos  -->
                        <template>
                            <v-row justify-center>
                                <v-dialog
                                    v-model="dialog_grafica_retardos"
                                    persistent
                                    max-width="500px"
                                    scrollable
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            slot="activator"
                                            class="botonModal"
                                            id="btnModal"
                                        ></v-btn>
                                    </template>
                                    <perfect-scrollbar style="background: #fff">
                                        <v-card elevation="0" class="borde-card">
                                            <div>
                                                <v-btn
                                                    @click="cerrarModal()"
                                                    small
                                                    slot="activator"
                                                    icon
                                                    text
                                                    class="v-btn-cerrar float-right mr-3 mt-3"
                                                >
                                                    <v-icon class="icono-cerrar"></v-icon>
                                                </v-btn>
                                            </div>
                                            <v-card-text>
                                                <div id="padre" v-show="!finaliza_carga_grafica_retardos">
                                                    <div id="loading">
                                                        <v-progress-circular
                                                        indeterminate
                                                        :size="100"
                                                        :width="7"
                                                        color="blue"
                                                        ></v-progress-circular>
                                                    </div>
                                                </div>
                                                <v-container
                                                    v-show="finaliza_carga_grafica_retardos"
                                                    grid-list-md
                                                >
                                                    <v-row wrap>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="rol != 'empleado'"
                                                        >
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%; padding:0px;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="retardo_template"
                                                                    :autoresize="true"
                                                                    @click="onClickRetardoTemplate"
                                                                    @mouseover="mouseover_retardo02"
                                                                    @mouseout="mouseout_retardo02"
                                                                />
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                                            v-if="mostrar_grafica_retardos"
                                                        >
                                                            <v-divider
                                                                :inset="inset"
                                                                class="espacio"
                                                            ></v-divider>
                                                            <div
                                                                slot="widget-content"
                                                                id="chartContainer"
                                                                style="height:350px; width:100%;"
                                                            >
                                                                <v-chart
                                                                    style="width:100%; height:350px; position:relative;"
                                                                    :options="retardo_template2"
                                                                    :autoresize="true"
                                                                    @mouseover="mouseover_retardo03"
                                                                    @mouseout="mouseout_retardo03"
                                                                />
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </v-card>
                                    </perfect-scrollbar>
                                </v-dialog>
                            </v-row>
                        </template>

                        <!-- fin template graficas retardos -->
                        <template
                            v-if="
                                rol == 'admin' ||
                                rol == 'admin-empresa' ||
                                rol == 'admin-sucursal'
                            "
                        >
                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="rol != 'empleado'">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pb-3 pl-8 pt-8 pr-8">
                                        <i class="material-icons-outlined icono">folder_open</i>
                                        <label class="leyenda" for="">
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estatus de expediente laboral
                                        </label>
                                    </v-card-title>
                                    <v-card-text class="pa-1 pt-0">
                                        <div
                                            slot="widget-content"
                                            id="chartDocumentos"
                                            style="height:235px; width:100%; margin-bottom: 20px;"
                                        >
                                            <template v-if="catidad_documentos > 0">
                                                <v-chart
                                                    :style="descriptionStyles"
                                                    :options="documentos"
                                                    :autoresize="true"
                                                    @click="onClickDocumentosTemplate"
                                                    @mouseover="mouseover_documentos"
                                                    @mouseout="mouseout_documentos"
                                                />
                                            </template>
                                            <template v-else>
                                                <h3 class="sin_documentos">
                                                No hay elementos para mostrar
                                                </h3>
                                            </template>
                                        </div>
                                        <!-- <v-col
                                            v-if="mostrarFlecha"
                                            cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                                            class=" ma-0 pa-0 d-flex justify-center"
                                        >
                                            <v-icon class="icono-flecha-doc"></v-icon>
                                        </v-col> -->
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <template>
                            <v-row justify-center>
                                <v-dialog
                                    v-model="dialog_documentos"
                                    persistent
                                    max-width="420px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonModal" id="btnModalDocumentos"></v-btn>
                                    </template>
                                    <v-card>
                                        <div>
                                            <v-btn
                                                @click="cerrarModal()"
                                                small
                                                slot="activator"
                                                icon
                                                text
                                                class="v-btn-cerrar float-right mr-3 mt-3"
                                            >
                                                <v-icon class="icono-cerrar"></v-icon>
                                            </v-btn>
                                        </div>
                                        <v-card-title class="padding-x50 padding_arriba pb-5">
                                            <div class="headerModalStart">
                                                <h2 class="titleModal_600_16">{{ titulo_modal }}</h2>
                                            </div>
                                        </v-card-title>
                                        <v-card-text class="padding-x50">
                                            <v-container grid-list-md key="unique" id="contenedor"> 
                                                <v-row class="pa-0 ma-0">
                                                    <template v-for="(i,index) in datosDocumento">
                                                        <v-row :key="index" @click="abrirListaEmpleados(i)" class="pa-0 ma-0" :class="i.faltantes.length !=0 ? 'cursor-pointer':''">
                                                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
                                                                <p class="text">{{i.sucursal}}</p>
                                                            </v-col>
                                                            <!-- <div class="col-1 pa-0">
                                                                <div :style="'background:'+colorDocumento + '; border-radius:5px; width:21px; height:21px;'"></div>
                                                            </div> -->
                                                            <p class="col-6 pa-0 subtitle my-0">Entregados:</p>
                                                            <p class="col-5 pa-0 px-0 text my-0"><span :class="i.entregados != null ? 'd-flex justify-end':'text-mute'">{{i.entregados!= null ? i.entregados.length:"Información faltante*"}}</span></p>
                                                            <!-- <div class="col-1 py-0"></div> -->
                                                            <p class="col-6 pa-0 subtitle pt-3">Faltantes:</p>
                                                            <p class="col-5 pa-0 px-0 text pt-3"><span :class="i.faltantes != null ? 'd-flex justify-end':'text-mute'">{{i.faltantes!= null ? i.faltantes.length:"Información faltante*"}}</span></p>
                                                            <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 pb-4">
                                                                <v-divider></v-divider>
                                                            </v-col>
                                                        </v-row>
                                                    </template>                                                
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                        </template>

                        <template>
                            <v-row justify-center>
                                <v-dialog
                                    v-model="dialog_empleados"
                                    persistent
                                    max-width="420px"
                                    scrollable
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" class="botonModal" id="btnModalEmpleados"></v-btn>
                                    </template>
                                    
                                    <v-card>
                                        <div class="">
                                            <v-btn
                                                @click="cerrarListaEmpleados()"
                                                small
                                                slot="activator"
                                                icon
                                                text
                                                class="v-btn-cerrar d-flex float-right mr-3 mt-3"
                                            >
                                                <v-icon class="icono-cerrar"></v-icon>
                                            </v-btn>
                                        </div>
                                        <v-card-title class="padding-x50 pb-0">
                                            <div class="headerModalStart">
                                                <h2 class="titleModal_600_16">{{ titulo_modal_empleados }}</h2>
                                            </div>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="px-0 pb-0">
                                                <p class="subtitle">{{nombre_sucursal}}</p>
                                            </v-col>
                                        </v-card-title>
                                        <perfect-scrollbar style="background: #fff">
                                            <v-card-text class="padding-x50 pt-0">
                                                <v-container grid-list-md key="unique" id="contenedor">
                                                    <v-row class="pa-0 ma-0">
                                                        <template v-for="(i,index) in datosEmpleados">
                                                            <v-row :key="index" class="pa-0 ma-0">
                                                                <p class="col-12 py-0 px-0 text-empleado my-0"><span :class="i.nombre_completo != null ? '':'text-mute'">{{i.nombre_completo!= null ? i.nombre_completo:"Información faltante*"}}</span></p>
                                                                <p class="col-12 py-0 px-0 text-departamento"><span :class="i.departamento != null ? '':'text-mute'">{{i.departamento!= null ? i.departamento:"Información faltante*"}}</span></p>
                                                            </v-row>
                                                        </template>                                     
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                        </perfect-scrollbar>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                        </template>

                        <!-- TABLE DE CUMPLEAÑOS -->
                        <template>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pb-3 pl-8 pt-8 pr-8">
                                        <v-col cols="12" xs="6" sm="7" md="8" lg="8" xl="9" class="pa-0">
                                            <v-icon class="icono outlined_v_icon">cake</v-icon>
                                            <!-- <i class="material-icons-outlined icono">cake</i> -->
                                            <label class="leyenda" for="">&nbsp;&nbsp;Cumpleaños</label>
                                        </v-col>
                                        <v-col cols="12" xs="6" sm="5" md="4" lg="4" xl="3" class="pa-0 dashboard-inputs2">
                                            <v-select
                                                :items="meses_c"
                                                item-text="nombre"
                                                item-value="id"
                                                hide-details
                                                v-model="mes_selecionado"
                                                outlined
                                                dense
                                                class="ma-0 pa-0"
                                            ></v-select>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-text class="pa-0">
                                        <simple-table
                                        :classes="tableClasses"
                                        :rows="itemsEmpleados"
                                        :columns="headersCumpleanios"
                                        :perPage="[5, 25, 50, 100]"
                                        shortPagination
                                        :idTabla="'tablaCumpleanios'"
                                        >
                                        <tbody slot="body" slot-scope="{ data }">
                                            <tr :key="item.id" v-for="item in data">
                                            <td>
                                                <div class="tblPrincipal">
                                                {{ item.nombre_completo }}
                                                </div>
                                                <div class="tblSecundario">{{ item.nombre_depto }}</div>
                                            </td>
                                            <td>
                                                <div>{{ item.cumpleanios }}</div>
                                            </td>
                                            </tr>
                                        </tbody>
                                        </simple-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>

                        <!-- TABLE DE ANIVERSARIOS -->
                        <template>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <v-card class="borde-card" elevation="0">
                                    <v-card-title class="pb-3 pl-8 pt-8 pr-8">
                                        <v-col cols="12" xs="6" sm="7" md="8" lg="8" xl="9" class="pa-0">
                                            <v-icon class="icono" >mdi-calendar-today</v-icon>
                                            <!-- <i class="material-icons-outlined icono">&nbsp;insert_invitation</i> -->
                                            <label class="leyenda" for="">&nbsp;&nbsp;Aniversario</label>
                                        </v-col>
                                        <v-col cols="12" xs="6" sm="5" md="4" lg="4" xl="3" class="pa-0 dashboard-inputs2">
                                            <v-select
                                                :items="meses_a"
                                                item-text="nombre"
                                                item-value="id"
                                                hide-details
                                                v-model="mes_selecionado2"
                                                outlined
                                                dense
                                                class="ma-0 pa-0"
                                            ></v-select>
                                        </v-col>
                                    </v-card-title>
                                    <v-card-text class="pa-0">
                                        <simple-table
                                            :classes="tableClasses"
                                            :rows="itemsAniversario"
                                            :columns="headersAniversario"
                                            :perPage="[5, 25, 50, 100]"
                                            shortPagination
                                            :idTabla="'tablaAniversarios'"
                                        >
                                            <tbody slot="body" slot-scope="{ data }">
                                                <tr :key="item.id" v-for="item in data" class="alto-tr">
                                                    <td>
                                                        <div class="tblPrincipal">
                                                            {{ item.nombre_completo }}
                                                        </div>
                                                        <div class="tblSecundario">{{ item.nombre_depto }}</div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            {{ item.fecha_aniversario }}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </simple-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>
                    </div>
                </template>

                <template v-if="primer_login">
					<v-row justify-center>
						<v-dialog
							v-model="dialog_primer_login"
							persistent
							max-width="420px"
						>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
							</template>
							<v-card>
								<ValidationObserver ref="form" v-slot="{ invalid }">
									<v-card-title class="padding-x50 padding_arriba pb-8">
										<span class="titleModal" v-text="titulo_modal"></span>
									</v-card-title>
									<v-card-text class="padding-x50">
										<v-container grid-list-md key="unique" id="contenedor"> 
											<v-row class="mx-0"><span id="span_cambio">Por políticas de seguridad, es requerido cambiar la contraseña.</span></v-row>
											<v-row class="pt-4 mx-0">
												<v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
													<ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required|verify_password">
														<v-text-field
															outlined
															label="Nueva contraseña"
															v-model="nuevo_password"
															id="nuevo_password"
															class="py-0"
															:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
															@click:append="show1 = !show1"
															:type="show1 ? 'text' : 'password'"
															auto
															:error-messages="errors" 
                                                    		required
														/>
													</ValidationProvider>
												</v-col>
												<v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
													<ValidationProvider v-slot="{ errors }" name="Confirmación" rules="required|verify_password">
														<v-text-field
															outlined
															label="Confirmar la contraseña"
															v-model="confirmacion"
															id="verificar_password"
															class="py-0"
															:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
															@click:append="show2 = !show2"
															:type="show2 ? 'text' : 'password'"
															auto
															@keyup.enter="verificar('confirmacion')"
															:error-messages="errors" 
                                                    		required
														/>
													</ValidationProvider>
												</v-col>
												<div
													v-if="password_igual"
													class="v-stepper__label"
													style="color:#FF5454; padding-left: 3%; margin-top: -5%;"
												>
													Las contraseñas no son iguales, favor de verificar.
												</div>
											</v-row>
										</v-container>
									</v-card-text>
									<v-card-actions class="d-flex justify-end padding-x50 padding_abajo">
										<v-btn color="btnGuardar" @click="verificar()">Guardar</v-btn>
									</v-card-actions>
								</ValidationObserver>
							</v-card>
						</v-dialog>
					</v-row>
				</template>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import userApi from "@/api/usuarios";
import empleadosApi from "@/api/empleados";
import dashboardApi from "@/api/dashboard";
import Notify from "@/plugins/notify";
import Datatable from "@/components/simpletable/Datatable.vue";
import PerfectScrollbar from "perfect-scrollbar";

export default {
    components: {
        "simple-table": Datatable,
    },
    data() {
        return {
            height_doc  : null,
            width_doc   : null,
            show1       : false,
            show2       : false,
            catidad_documentos: 0,
            meses_c: [
                { nombre: "Enero", id: 0 },
                { nombre: "Febrero", id: 1 },
                { nombre: "Marzo", id: 2 },
                { nombre: "Abril", id: 3 },
                { nombre: "Mayo", id: 4 },
                { nombre: "Junio", id: 5 },
                { nombre: "Julio", id: 6 },
                { nombre: "Agosto", id: 7 },
                { nombre: "Septiembre", id: 8 },
                { nombre: "Octubre", id: 9 },
                { nombre: "Noviembre", id: 10 },
                { nombre: "Diciembre", id: 11 },
            ],
            meses_a: [
                { nombre: "Enero", id: 0 },
                { nombre: "Febrero", id: 1 },
                { nombre: "Marzo", id: 2 },
                { nombre: "Abril", id: 3 },
                { nombre: "Mayo", id: 4 },
                { nombre: "Junio", id: 5 },
                { nombre: "Julio", id: 6 },
                { nombre: "Agosto", id: 7 },
                { nombre: "Septiembre", id: 8 },
                { nombre: "Octubre", id: 9 },
                { nombre: "Noviembre", id: 10 },
                { nombre: "Diciembre", id: 11 },
            ],
            mes_actual: null,
            mes_selecionado: null,
            mes_selecionado2: null,
            min_fecha: null,
            max_fecha: null,
            mostrar_grafica: false,
            mostrar_grafica_incidencia: false,
            mostrar_grafica_incidencia2: false,
            mostrar_grafica_retardos: false,
            tipo_incidencia: null,
            usuario: {
                id              : "",
                nombre          : "",
                apellido_paterno: "",
                apellido_materno: "",
                puesto          : "",
                email           : "",
                fecha_nacimiento: "",
                telefono        : "",
                celular         : "",
                password        : "",
                primer_inicio_sesion: "",
                rol_id          : "",
            },
            primer_login: false,
            titulo_modal: "Cambio de contraseña",
            dialog_primer_login: false,
            dialog_grafica: false,
            dialog_grafica_incidencia: false,
            dialog_grafica_retardos: false,
            finaliza_carga_grafica: false,
            finaliza_carga_grafica_incidencias: false,
            finaliza_carga_grafica_retardos: false,
            nuevo_password: "",
            confirmacion: "",
            password_igual: false,
            user_vuex: {
                email: "",
                password: "",
            },
            datosLogin: "",
            rol: "",
            cargaGrafica: false,
            incidencia: {
                id          : 0,
                descripcion : "",
                tipo_id     : "",
                fecha_inicio: "",
                fecha_final : "",
                estatus_id  : "",
                url_archivo : "",
            },
            empleado_id     : null,
            cliente_id      : null,
            empresa_id      : null,
            sucursal_id     : null,
            departamento_id : null,
            items           : [],
            tableClasses: {
                "t-head": {
                    "no-radius": true,
                    "titulo-first-child": true,
                },
            },
            headersCumpleanios: [
                { label: "Empleado", name: "nombre_completo", filterable: false },
                { label: "Cumpleaños", name: "cumpleanios", filterable: false },
            ],
            headersAniversario: [
                { label: "Empleado", name: "nombre_completo", filterable: false },
                { label: "Aniversario", name: "fecha_aniversario", filterable: false },
            ],
            itemsEmpleados  : [],
            itemsAniversario: [],
            mostrarFlecha   : false,
            pastelSinDatos: {
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        selectedMode:false,
                        name    : "Incidencia2",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal  : {
                                show    : true,
                                position: "center",
                                formatter   : (params) => {
                                    return "{top| Total}" + "\n" + "{bottom|0%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show    : true,
                                formatter: (params) => {
                                    return ("\n" + "{top| Total}" + "\n" + "{bottom|0%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        itemStyle:{
                            emphasis:{
                                shadowBlur:0,
                                shadowOffsetX:0,
                                shadowColor:'rgba(0,0,0,0.5)',
                                color:"#EFEFEF"
                            }
                        },
                        data: [ {value: 1, name: "Total",}],
                        color: ["#EFEFEF"],
                    },
                ],
            },
            pastel: {
                tooltip : {
                    trigger : "item",
                    //formatter: "{a} </br>{b} : {c}({d}%)"
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle       : {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        name    : "Incidencia",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal  : {
                                show    : true,
                                position: "center",
                                formatter   : (params) => {
                                    return "{top| Incidencia}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 400,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show    : true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name +"}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        color: [],
                    },
                ],
            },
            incidencia_template: {
                title: {
                    text    : "Incidencias",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                trigger: "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink : false,
                        name            : "Incidencia",
                        type            : "pie",
                        radius          : ["50%", "70%"],
                        center          : ["50%", "50%"],
                        label: {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Incidencia}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth : 2,
                                borderColor : "#fff",
                            },
                        },
                        /* color:  [ 
                                '#17B5FE','#F7CA18','#C2E860','#F8690E','#FF4E71',
                                '#4FD2E0','#03E179','#035BFF','#5F5C61','#0A9D0A',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#839B49','#6D8236','#F318A5',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#101C5A','#172674','#2C3E9E','#3C4CA4','#4C5AA6','#54609E','#69729D','#8289AB','#A8ACBE']
                        color: [
                            "#C20606",
                            "#FF4105",
                            "#E74B1A",
                            "#FE5F55",
                            "#FFB997",
                            "#FFD400",
                            "#1DD3B0",
                            "#00BD6E",
                            "#1CA36A",
                        ],
                    },
                ],
            },
            incidencia_template2: {
                title: {
                    text    : "Incidencias",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                        `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                        parms.data.name +
                        "</br>" +
                        "Total：" +
                        parms.data.value +
                        "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name    : "Incidencia",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Incidencia}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#333333','#514747','#634F4F','#815A5A','#9C6060','#C67979','#DE8282','#F7A2A2','#FEBABA']
                        color: [
                            "#390099",
                            "#6016DC",
                            "#A816DC",
                            "#CC5CF3",
                            "#843B62",
                            "#74546A",
                            "#FFCFDB",
                            "#F85E83",
                            "#DA225F",
                            "FF0054",
                        ],
                    },
                ],
            },
            incidencia_template3: {
                title: {
                    text    : "Incidencias",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name: "Incidencia",
                        type: "pie",
                        radius: ["50%", "70%"],
                        center: ["50%", "50%"],
                        label: {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                return "{top| Incidencia}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 10, 0],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#F1E640','#B1AA2E'
                                ] */

                        //color : ['#420243','#610F63','#781F7A','#963B98','#AF5CB0','#C775C9','#CE91CF','#D5A9D6','#DBBEDC']
                        color: [
                            "#0081A7",
                            "#00AFB9",
                            "#FDFCDC",
                            "#EDEEC9",
                            "#FED9B7",
                            "#797D62",
                            "#77BFA3",
                            "#BFD8BD",
                            "#114B5F",
                        ],
                    },
                ],
            },
            p: null,
            rotacion: {
                tooltip : {
                    trigger : "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                            //+"Porcentaje："+ parms.percent+"%";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name    : "Rotación",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal  : {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Rotación}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom          : {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color: ['#101C5A','#172674','#2C3E9E','#3C4CA4','#4C5AA6','#54609E','#69729D','#8289AB','#A8ACBE']
                        color: [
                            "#C20606",
                            "#FF4105",
                            "#E74B1A",
                            "#FE5F55",
                            "#FFB997",
                            "#FFD400",
                            "#1DD3B0",
                            "#00BD6E",
                            "#1CA36A",
                        ],
                    },
                ],
            },
            rotacion_template: {
                title: {
                    text: "Rotación",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                        `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                        parms.data.name +
                        "</br>" +
                        "Total：" +
                        parms.data.value +
                        "</br>";
                        //+"Porcentaje："+ parms.percent+"%";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize: 12,
                        fontWeight: 500,
                        fontFamily: "Montserrat",
                        fontStyle: "normal",
                        color: "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name    : "Rotación",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Rotación}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return (
                                        "\n" +
                                        "{top| " +
                                        params.data.name +
                                        "}" +
                                        "\n" +
                                        "{bottom|" +
                                        params.percent +
                                        "%}"
                                    );
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#17B5FE','#F7CA18','#C2E860','#F8690E','#FF4E71',
                                '#4FD2E0','#03E179','#035BFF','#5F5C61','#0A9D0A',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#839B49','#6D8236','#F318A5',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#333333','#514747','#634F4F','#815A5A','#9C6060','#C67979','#DE8282','#F7A2A2','#FEBABA']
                        color: [
                            "#390099",
                            "#6016DC",
                            "#A816DC",
                            "#CC5CF3",
                            "#843B62",
                            "#74546A",
                            "#FFCFDB",
                            "#F85E83",
                            "#DA225F",
                            "FF0054",
                        ],
                    },
                ],
            },
            rotacion_template2: {
                title: {
                    text    : "Rotación",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                            //+"Porcentaje："+ parms.percent+"%";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name    : "Rotación",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Rotación}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#420243','#610F63','#781F7A','#963B98','#AF5CB0','#C775C9','#CE91CF','#D5A9D6','#DBBEDC']
                        color: [
                            "#0081A7",
                            "#00AFB9",
                            "#FDFCDC",
                            "#EDEEC9",
                            "#FED9B7",
                            "#797D62",
                            "#77BFA3",
                            "#BFD8BD",
                            "#114B5F",
                        ],
                    },
                ],
            },
            retardos: {
                tooltip: {
                    trigger: "item",
                    //formatter: "{a} </br>{b} : {c}({d}%)"
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false, //para desactivar el hover en la leyenda
                        name    : "Retardo",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label: {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Retardo}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 12,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#101C5A','#172674','#2C3E9E','#3C4CA4','#4C5AA6','#54609E','#69729D','#8289AB','#A8ACBE']
                        color: [
                            "#C20606",
                            "#FF4105",
                            "#E74B1A",
                            "#FE5F55",
                            "#FFB997",
                            "#FFD400",
                            "#1DD3B0",
                            "#00BD6E",
                            "#1CA36A",
                        ],
                    },
                ],
            },
            retardo_template: {
                title: {
                    text: "Retardo",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                            //+"Porcentaje："+ parms.percent+"%";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name: "Retardo",
                        type: "pie",
                        radius: ["50%", "70%"],
                        center: ["50%", "50%"],
                        label: {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Retardo}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#17B5FE','#F7CA18','#C2E860','#F8690E','#FF4E71',
                                '#4FD2E0','#03E179','#035BFF','#5F5C61','#0A9D0A',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#BFBFBF','#404040','#839B49','#6D8236','#F318A5',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#333333','#514747','#634F4F','#815A5A','#9C6060','#C67979','#DE8282','#F7A2A2','#FEBABA']
                        color: [
                            "#390099",
                            "#6016DC",
                            "#A816DC",
                            "#CC5CF3",
                            "#843B62",
                            "#74546A",
                            "#FFCFDB",
                            "#F85E83",
                            "#DA225F",
                            "FF0054",
                        ],
                    },
                ],
            },
            retardo_template2: {
                title: {
                    text    : "Retardo",
                    x       : "left",
                    textStyle: {
                        color       : "#444975",
                        fontFamily  : "Montserrat",
                        fontSize    : 18,
                        fontWeight  : 600,
                    },
                },
                tooltip: {
                    trigger: "item",
                    formatter: function(parms) {
                        var str =
                            `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                            parms.data.name +
                            "</br>" +
                            "Total：" +
                            parms.data.value +
                            "</br>";
                            //+"Porcentaje："+ parms.percent+"%";
                        return str;
                    },
                },
                legend: {
                    icon    : "circle",
                    orient  : "horizontal",
                    type    : "scroll",
                    y       : "bottom",
                    text    : "top",
                    textStyle: {
                        fontSize    : 12,
                        fontWeight  : 500,
                        fontFamily  : "Montserrat",
                        fontStyle   : "normal",
                        color       : "#96999A",
                    },
                },
                series: [
                    {
                        legendHoverLink: false,
                        name    : "Retardo",
                        type    : "pie",
                        radius  : ["50%", "70%"],
                        center  : ["50%", "50%"],
                        label   : {
                            normal: {
                                show: true,
                                position: "center",
                                formatter: (params) => {
                                    return "{top| Retardo}" + "\n" + "{bottom|100%}";
                                },
                                rich: {
                                    top: {
                                        color: "#96999A",
                                        fontSize: 16,
                                        fontWeight: 600,
                                        fontFamily: "Montserrat",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color: "#96999A",
                                        fontSize: 40,
                                        fontWeight: 600,
                                        fontFamily: "Montserrat",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        letterSpacing: "normal",
                                        padding: [0, 0, 0, 5],
                                    },
                                },
                            },
                            emphasis: {
                                show: true,
                                formatter: (params) => {
                                    return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent +"%}");
                                },
                                rich: {
                                    top: {
                                        color       : "#96999A",
                                        fontSize    : 16,
                                        fontWeight  : 500,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                    },
                                    bottom: {
                                        color       : "#96999A",
                                        fontSize    : 40,
                                        fontWeight  : 600,
                                        fontFamily  : "Montserrat",
                                        fontStretch : "normal",
                                        fontStyle   : "normal",
                                        letterSpacing: "normal",
                                        padding     : [0, 0, 0, 5],
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [],
                        itemStyle: {
                            normal: {
                                borderWidth: 2,
                                borderColor: "#fff",
                            },
                        },
                        /* color:  [ 
                                '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                                '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                                '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                                '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                                '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                                '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                                '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                                '#F1E640','#B1AA2E'
                                ] */
                        //color : ['#420243','#610F63','#781F7A','#963B98','#AF5CB0','#C775C9','#CE91CF','#D5A9D6','#DBBEDC']
                        color: [
                            "#0081A7",
                            "#00AFB9",
                            "#FDFCDC",
                            "#EDEEC9",
                            "#FED9B7",
                            "#797D62",
                            "#77BFA3",
                            "#BFD8BD",
                            "#114B5F",
                        ],
                    },
                ],
            },
            documentos: {
                grid: {
                    left: "5%",
                    right: "5%",
                    bottom: "5%",
                    top: "5%",
                    containLabel: false,
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "none",
                    },
                    formatter: function(params) {
                        return (
                            params[0].name +
                            "<br/>" +
                            "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                            params[0].value +
                            "% <br/>"
                        );
                    },
                    /* formatter: function(params) {
                                return params[0].name + '<br/>' +
                                    "<span style='display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:rgba(36,207,233,0.9)'></span>" +
                                    params[0].seriesName + ' : ' + Number((params[0].value.toFixed(4) / 10000).toFixed(2)).toLocaleString() + '<br/>'
                        } */
                },
                backgroundColor: "#FFFFFF",
                xAxis: {
                    show: false,
                    type: "value",
                },
                yAxis: [
                    {
                        type    : "category",
                        inverse : true,
                        axisLabel: {
                            show: false, //poner el label del lado izquierdo sobre el eje y
                            textStyle: {
                                color: "#96999A",
                                fontFamily: "Montserrat",
                            },
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            show: false,
                        },
                        data: [],
                    },
                    {
                        type    : "category",
                        offset  : -80,
                        inverse : true,
                        axisTick: "none",
                        axisLine: "none",
                        show    : true,
                        axisLabel: {
                            show    : true,
                            inside  : false,
                            verticalAlign: "right",
                            lineHeight: "-40",
                            textStyle   : {
                                color       : "#96999A",
                                fontSize    : "12",
                                fontFamily  : "Montserrat",
                            },
                            formatter: function(value) {
                                let str = "";
                                if (value == 100) {
                                    return "¡Completo!";
                                } else {
                                    return "            " + value.toLocaleString() + "%";
                                }
                            },
                        },
                        data: [],
                    },
                ],
                series: [
                    {
                        name: "No. Documentos",
                        showBackground: true,
                        backgroundStyle: {
                            color: "#F2F2F2",
                            barBorderRadius: 5,
                        },
                        type: "bar",
                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 5,
                                color: function(params) {
                                    if (params.data.value == 100) {
                                        return "#50E18E";
                                    } 
                                    else if(params.data.value > 50 && params.data.value < 100){
                                        return "#FFC85F";
                                    }
                                    else {
                                        return "#E75858";
                                    }
                                },
                            },
                        },
                        barWidth: 20,
                        data: [],
                        label: {
                            normal: {
                                color: "#b3ccf8",
                                show: true,
                                position: [0, "-20px"],
                                textStyle: {
                                    fontSize: 16,
                                    fontFamily: "Montserrat",
                                },
                                formatter: function(a) {
                                    let num = "";
                                    let str = "";
                                    str = `{colorTitulo| ${a.name}}`;
                                    return str;
                                },
                                rich: {
                                    colorTitulo: {
                                        color: "#57575C", //color titulo de documento
                                        fontWeight: 600,
                                        fontFamily: "Montserrat",
                                    },
                                },
                            },
                        },
                    },
                    {
                        name: "nombre2",
                        type: "bar",
                        barWidth: 20,
                        barGap: "-100%",
                        data: [],
                        itemStyle: {
                            normal: {
                                color: "#F2F2F2",
                                barBorderRadius: 5,
                            },
                        },
                    },
                ],
            }, //termina documentos
            showIncidencias: false,
            showRotacion: false,
            showRetardos: false,
            dialog_documentos :false,
            dialog_empleados : false,
            datosDocumento: null,
            datosEmpleados: null,
            colorDocumento: '#E75858',
            titulo_modal_empleados: '',
            nombre_sucursal:'',
        };
    },
    computed: {
        ...mapState("auth", ["datos", "user"]),
        descriptionStyles() {
            return { width: this.height_doc, height: this.width_doc };
        },
        incidenciasShow(){
            let menu = this.$session.get("menu");
            let vistaIncidencia = menu.find( vista => vista.url == "/incidencias" );
            if (vistaIncidencia){
                return true;
            }
            else{
                return false;
            }
        },
        rotacionShow(){
            let menu = this.$session.get("menu");
            let vistaEmpleados = menu.find( vista => vista.url == "/empleados" );
            if (vistaEmpleados){
                return true;
            }
            else{
                return false;
            }
        },
        retardosShow(){
            let menu = this.$session.get("menu");
            let vistaAsistencias = menu.find( vista => vista.url == "/asistencia" );
            if (vistaAsistencias){
                return true;
            }
            else{
                return false;
            }
        },
        displayGraficas(){
            if( this.incidenciasShow == true && this.rotacionShow == true && this.retardosShow == true ){
                return '4';
            }
            else if( this.incidenciasShow == true && this.rotacionShow == true && this.retardosShow == false ){
                return '6';
            }
            else if ( this.incidenciasShow == false && this.rotacionShow == true && this.retardosShow == true ){
                return '6';
            }
            else if(this.incidenciasShow == true  && this.rotacionShow == false && this.retardosShow == true ){
                return '6';
            }
            else if( this.incidenciasShow == true && this.rotacionShow == false && this.retardosShow == false ){
                return '12';
            }
            else if ( this.incidenciasShow == false && this.rotacionShow == true && this.retardosShow == false ){
                return '12';
            }
            else if(this.incidenciasShow == false  && this.rotacionShow == false && this.retardosShow == true ){
                return '12';
            }
            else{
                return '12';
            }
        }
    },
    watch: {
        mes_selecionado: function(val) {
            this.cambioMes(val);
        },
        mes_selecionado2: function(val) {
            this.cambioMesAniversario(val);
        },
    },
    methods: {
        ...mapActions("auth", ["logout", "update_password", "update_cliente"]),
        inicio() {
            if (this.datos.primer_inicio_sesion == true) {
                this.primer_login = true;
                this.dialog_primer_login = true;
                setTimeout(function() {
                    document.getElementById("nuevo_password").focus();
                }, 250);
            }
            this.datosLogin = this.$session.get("usuario");

            this.rol = this.datosLogin.rol.name;
            let empleado_id = null;
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();
            this.min_fecha = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            this.max_fecha =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();
            let paramCumple = {};
            if (this.rol != "admin-depto") {
                paramCumple = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    min_fecha   : inicio,
                    max_fecha   : final,
                    rol_name    : this.rol,
                };
            } 
            else {
                paramCumple = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    min_fecha   : inicio,
                    max_fecha   : final,
                    rol_name    : this.rol,
                };
            }

        if (this.rol != "root") {
            empleadosApi.getDashboard(paramCumple).then((response) => {
                let sin_incidencias = 0;
                let format_incidencias = response.incidencias;
                format_incidencias.forEach((element) => {
                    if (element["total"] == 0) {
                        sin_incidencias++;
                        if (sin_incidencias == format_incidencias.length) {
                            let b = {
                                value: 1,
                                name: "Sin incidencias",
                            };
                            this.pastel.series[0].data.push(b);
                            this.pastel.series[0].color.push("#03E179");
                        }
                    }
                    if (element["total"] != 0) {
                        this.showIncidencias = true;
                        let b = {
                            value: element["total"],
                            name: element["nombre"],
                            tipo_incidencia: element["tipo_id"],
                        };
                        this.pastel.series[0].data.push(b);
                        this.pastel.series[0].color.push(element["color"]);
                    }
                });
                let sin_rotacion = 0;
                let format_rotacion = response.rotacion;
                if (this.rol == "admin" || "admin-empresa" || "admin-sucursal") {
                    if (format_rotacion.length == 0 || format_rotacion == undefined) {
                        let b = { value: 1, name: "Sin rotación" };
                        this.rotacion.series[0].data.push(b);
                    } else {
                        this.showRotacion = true;
                        format_rotacion.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.rotacion.series[0].data.push(b);
                        });
                    }
                }
                let sin_retardos = 0;
                let format_retardos = response.retardos;
                if (this.rol == "admin" || "admin-empresa" || "admin-sucursal") {
                    if (format_retardos.length == 0 || format_retardos == undefined) {
                        let b = { value: 1, name: "Sin retardos" };
                        this.retardos.series[0].data.push(b);
                    } else {
                        this.showRetardos = true;
                        format_retardos.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.retardos.series[0].data.push(b);
                        });
                    }
                }

                this.catidad_documentos = response.documentos.length;
                let format_documentos = response.documentos.sort( (a, b) => a.total - b.total);
                let porcentaje = 100;
                if (this.rol == "admin" || "admin-empresa" || "admin-sucursal") {
                    format_documentos.forEach((element) => {
                        /* this.documentos.yAxis[0].data.push(element.nombre);
                        this.documentos.yAxis[1].data.push(element.total);
                        this.documentos.series[0].data.push(element.total);
                        this.documentos.series[1].data.push(porcentaje); */

                        let a = { value: element.nombre, documento: element};
                        let b = { value: element.total, documento: element};
                        let c = { value: element.total, documento: element};
                        let d = { value: porcentaje, documento: element};
                        this.documentos.yAxis[0].data.push(a);
                        this.documentos.yAxis[1].data.push(b);
                        this.documentos.series[0].data.push(c);
                        this.documentos.series[1].data.push(d);

                    });

                    if (this.documentos.series[1].data.length > 3) {
                        this.mostrarFlecha = true;
                    }

                    switch (this.documentos.series[1].data.length) {
                        case 0:
                            this.width_doc = "50%";
                            break;
                        case 1:
                            this.width_doc = "60%";
                            break;
                        case 2:
                            this.width_doc = "70%";
                            break;
                        case 3:
                            this.width_doc = "100%";
                            break;
                        case 4:
                            this.width_doc = "150%";
                            break;
                        case 5:
                            this.width_doc = "170%";
                            break;
                        case 6:
                            this.width_doc = "200%";
                            break;
                        case 7:
                            this.width_doc = "250%";
                            break;
                        case 8:
                            this.width_doc = "270%";
                            break;
                        case 9:
                            this.width_doc = "300%";
                            break;
                        case 10:
                            this.width_doc = "300%";
                            break;
                        case 11:
                            this.width_doc = "320%";
                            break;
                        case 12:
                            this.width_doc = "340%";
                            break;
                        case 13:
                            this.width_doc = "360%";
                            break;
                        case 14:
                            this.width_doc = "380%";
                            break;
                        case 15:
                            this.width_doc = "400%";
                            break;
                        case 16:
                            this.width_doc = "420%";
                            break;
                        case 17:
                            this.width_doc = "440%";
                            break;
                        case 18:
                            this.width_doc = "460%";
                            break;
                        case 19:
                            this.width_doc = "480%";
                            break;
                        case 20:
                            this.width_doc = "500%";
                            break;
                    }
                    if (this.documentos.series[1].data.length > 20) {
                        this.width_doc = "500%";
                    }
                    this.height_doc = "100%";
                }

                this.tabla_css();
                this.cargaGrafica = true;

                const elementScroll = document.querySelector("#chartDocumentos");
                if (elementScroll) {
                    const ps = new PerfectScrollbar(elementScroll, {
                        wheelSpeed: 9,
                        wheelPropagation: true,
                        minScrollbarLength: 5,
                    });
                }
            });
        }

        this.mes_selecionado = date.getMonth();
        this.mes_selecionado2 = date.getMonth();
        },
        cambioMes() {
            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), this.mes_selecionado, 1);
            var ultimoDia = new Date(date.getFullYear(), this.mes_selecionado + 1, 0);
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

            let paramCumpleanios = {
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                cliente_id: this.datosLogin.cliente_id,
                empresa_id: this.datosLogin.empresa_id,
                sucursal_id: this.datosLogin.sucursal_id,
                departamento_id: this.datosLogin.departamento_id,
                min_fecha: inicio,
                max_fecha: final,
                rol_name: this.datosLogin.rol.name,
            };

            if (this.rol != "root") {
                empleadosApi.cumpleaniosEmpleado(paramCumpleanios).then((response) => {
                    let format = response.cumpleanios;
                    format.forEach((element) => {
                        element["nombre_completo"] = element["nombre"] + " " + element["apellido_paterno"];
                        let fnacimiento = element["fecha_nacimiento"].split("-");
                        element["cumpleanios"] =
                            fnacimiento[2] +
                            " / " +
                            fnacimiento[1] +
                            " / " +
                            fnacimiento[0];
                    });
                    this.itemsEmpleados = format;
                });
            }
        },
        cambioMesAniversario() {
            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), this.mes_selecionado2, 1);
            var ultimoDia = new Date(
                date.getFullYear(),
                this.mes_selecionado2 + 1,
                0
            );
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

            let paramCumpleanios = {
                rol_name    : this.datosLogin.rol.name,
                usuario_id  : this.datosLogin.id,
                cliente_id  : this.datosLogin.cliente_id,
                empresa_id  : this.datosLogin.empresa_id,
                sucursal_id : this.datosLogin.sucursal_id,
                departamento_id: this.datosLogin.departamento_id,
                min_fecha   : inicio,
                max_fecha   : final,
                rol_name    : this.datosLogin.rol.name,
            };

            if (this.rol != "root") {
                empleadosApi.cumpleaniosEmpleado(paramCumpleanios).then((response) => {
                    let format_aniversario = response.aniversarios;
                    format_aniversario.forEach((element) => {
                        
                        element["nombre_completo"] = element["nombre"] + " " + element["apellido_paterno"];
                        let faniversario = element["fecha_ingreso"].split("-");
                        element["fecha_aniversario"] =
                            faniversario[2] +
                            " / " +
                            faniversario[1] +
                            " / " +
                            faniversario[0];
                    });
                    this.itemsAniversario = format_aniversario;
                });
            }
        },
        mouseover_incidencia01(event, chartContext, config) {
            this.pastel.series[0].label.normal.show = false;
        },
        mouseout_incidencia01(event, chartContext, config) {
            this.pastel.series[0].label.normal.show = true;
        },
        mouseover_incidencia02(event, chartContext, config) {
            this.incidencia_template.series[0].label.normal.show = false;
        },
        mouseout_incidencia02(event, chartContext, config) {
            this.incidencia_template.series[0].label.normal.show = true;
        },
        mouseover_incidencia03(event, chartContext, config) {
            this.incidencia_template2.series[0].label.normal.show = false;
        },
        mouseout_incidencia03(event, chartContext, config) {
            this.incidencia_template2.series[0].label.normal.show = true;
        },
        mouseover_incidencia04(event, chartContext, config) {
            this.incidencia_template3.series[0].label.normal.show = false;
        },
        mouseout_incidencia04(event, chartContext, config) {
            this.incidencia_template3.series[0].label.normal.show = true;
        },
        onClickIncidencia(event, instance, ECharts) {
            let self = this;
            self.tipo_incidencia = arguments[0].data.tipo_incidencia;
            if (arguments[0].data.name != "Sin incidencias") {
                this.finaliza_carga_grafica_incidencias = false;
                if (this.rol == "admin") {
                    let parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        cliente_id  : this.datosLogin.cliente_id,
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                        mostrar     : "empresa",
                        tipo_incidencia: self.tipo_incidencia,
                    };
                    dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                        let format_incidencia = response;
                        format_incidencia.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                empresa_id: element[0].id,
                                tipo_incidencia: self.tipo_incidencia,
                            };
                            this.incidencia_template.series[0].data.push(b);
                        });
                        this.incidencia_template.title.text     = "" + arguments[0].data.name;
                        this.finaliza_carga_grafica_incidencias = true;
                        this.dialog_grafica_incidencia          = true;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                } 
                else if (this.rol == "admin-empresa") {
                    let parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        empresa_id  : this.datosLogin.empresa_id,
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                        mostrar     : "sucursal",
                        tipo_incidencia: self.tipo_incidencia,
                    };
                    dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                        let format_incidencia_empresa = response;
                        format_incidencia_empresa.forEach((element) => {
                            let b = {
                                value       : element.length,
                                name        : element[0].nombre,
                                sucursal_id : element[0].id,
                                tipo_incidencia: self.tipo_incidencia,
                            };
                            this.incidencia_template.series[0].data.push(b);
                        });
                            this.incidencia_template.title.text = "" + arguments[0].data.name;
                            this.finaliza_carga_grafica_incidencias = true;
                            this.dialog_grafica_incidencia = true;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } 
                else if (this.rol == "admin-sucursal") {
                    let parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        sucursal_id : this.datosLogin.sucursal_id,
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                        mostrar     : "departamento",
                        tipo_incidencia: self.tipo_incidencia,
                    };
                    dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                        let format_incidencia_sucursal = response;
                        format_incidencia_sucursal.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                departamento_id: element[0].id,
                                tipo_incidencia: self.tipo_incidencia,
                            };
                            this.incidencia_template.series[0].data.push(b);
                        });
                        this.incidencia_template.title.text = "" + arguments[0].data.name;
                        this.finaliza_carga_grafica_incidencias = true;
                        this.dialog_grafica_incidencia = true;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }
        },
        onClickIncidenciaTemplate() {
            if (
                (this.mostrar_grafica_incidencia == true) &
                (this.mostrar_grafica_incidencia2 == true)
            ) {
                this.mostrar_grafica_incidencia2 = false;
            }

            let self = this;
            if (this.rol == "admin") {
                this.incidencia_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    empresa_id  : arguments[0].data.empresa_id,
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                    mostrar     : "sucursal",
                    tipo_incidencia: self.tipo_incidencia,
                };
                dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                    let format_incidencia = response;
                    format_incidencia.forEach((element) => {
                        let b = {
                            value: element.length,
                            name: element[0].nombre,
                            sucursal_id: element[0].id,
                        };
                        this.incidencia_template2.series[0].data.push(b);
                    });
                    this.incidencia_template2.title.text = arguments[0].data.name;
                    this.mostrar_grafica_incidencia = true;
                })
                .catch((err) => console.log(err));
            } 
            else if (this.rol == "admin-empresa") {
                this.incidencia_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    sucursal_id : arguments[0].data.sucursal_id,
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                    mostrar     : "departamento",
                    tipo_incidencia: self.tipo_incidencia,
                };
                dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                    let format_incidencia_empresa = response;
                    format_incidencia_empresa.forEach((element) => {
                        let b = {
                            value: element.length,
                            name: element[0].nombre,
                            departamento_id: element[0].id,
                            tipo_incidencia: self.tipo_incidencia,
                        };
                        this.incidencia_template2.series[0].data.push(b);
                    });
                    this.incidencia_template2.title.text = arguments[0].data.name;
                    this.mostrar_grafica_incidencia = true;
                })
                .catch((err) => console.log(err));
            }
        },
        onClickIncidenciaTemplate2() {
            let self = this;
            if (this.rol == "admin") {
                this.incidencia_template3.series[0].data = [];
                let parametros = {
                    rol_name: this.datosLogin.rol.name,
                    usuario_id: this.datosLogin.id,
                    sucursal_id: arguments[0].data.sucursal_id,
                    min_fecha: this.min_fecha,
                    max_fecha: this.max_fecha,
                    mostrar: "departamento",
                    tipo_incidencia: self.tipo_incidencia,
                };
                dashboardApi.getDashboardIncidencias(parametros).then((response) => {
                    let format_incidencia = response;
                    format_incidencia.forEach((element) => {
                        let b = { value: element.length, name: element[0].nombre };
                        this.incidencia_template3.series[0].data.push(b);
                    });
                    this.incidencia_template3.title.text = arguments[0].data.name;
                    this.mostrar_grafica_incidencia2 = true;
                })
                .catch((err) => console.log(err));
            }
        },
        mouseover_rotacion01(event, chartContext, config) {
            this.rotacion.series[0].label.normal.show = false;
        },
        mouseout_rotacion01(event, chartContext, config) {
            this.rotacion.series[0].label.normal.show = true;
        },
        mouseover_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = false;
        },
        mouseout_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = true;
        },
        mouseover_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = false;
        },
        mouseout_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = true;
        },
        onClickRotacion(event, instance, ECharts) {
            if (arguments[0].data.name != "Sin rotación") {
                this.finaliza_carga_grafica = false;
                let parametros = null;
                if (this.rol == "admin") {
                    parametros = {
                        rol_name: this.datosLogin.rol.name,
                        usuario_id: this.datosLogin.id,
                        empresa_id: arguments[0].data.id,
                        rol_name: "admin-empresa",
                        min_fecha: this.min_fecha,
                        max_fecha: this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
                        let format_rotacion = response;
                        format_rotacion.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.rotacion_template.series[0].data.push(b);
                        });
                        this.rotacion_template.title.text = `Empresa: ${arguments[0].data.name}`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                } 
                else if (this.rol == "admin-empresa") {
                    parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        sucursal_id : arguments[0].data.id,
                        rol_name    : "admin-sucursal",
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
                        let format_rotacion = response;
                        format_rotacion.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.rotacion_template.series[0].data.push(b);
                        });
                        this.rotacion_template.title.text = `Sucursal: ${arguments[0].data.name}`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                }
            }
        },
        onClickRotacionTemplate() {
            if (this.rol == "admin") {
                this.rotacion_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    sucursal_id : arguments[0].data.id,
                    rol_name    : "admin-sucursal",
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                };
                dashboardApi.getDashboardRotacion(parametros).then((response) => {
                    let format_rotacion = response;
                    format_rotacion.forEach((element) => {
                        let b = {
                            value: element.length,
                            name: element[0].nombre,
                            id: element[0].id,
                        };
                        this.rotacion_template2.series[0].data.push(b);
                    });
                    this.rotacion_template2.title.text = "Sucursal: " + arguments[0].data.name;
                    this.mostrar_grafica = true;
                })
                .catch((err) => console.log(err));
            }
        },
        mouseover_retardo01(event, chartContext, config) {
            this.retardos.series[0].label.normal.show = false;
        },
        mouseout_retardo01(event, chartContext, config) {
            this.retardos.series[0].label.normal.show = true;
        },
        mouseover_retardo02(event, chartContext, config) {
            this.retardo_template.series[0].label.normal.show = false;
        },
        mouseout_retardo02(event, chartContext, config) {
            this.retardo_template.series[0].label.normal.show = true;
        },
        mouseover_retardo03(event, chartContext, config) {
            this.retardo_template2.series[0].label.normal.show = false;
        },
        mouseout_retardo03(event, chartContext, config) {
            this.retardo_template2.series[0].label.normal.show = true;
        },

        onClickRetardo(event, instance, ECharts) {
            if (arguments[0].data.name != "Sin retardos") {
                this.finaliza_carga_grafica = false;
                let parametros = null;
                if (this.rol == "admin") {
                    parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        empresa_id  : arguments[0].data.id,
                        rol_name    : "admin-empresa",
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                    };
                    dashboardApi.getDashboardRetardos(parametros).then((response) => {
                        let format_retardos = response;
                        format_retardos.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.retardo_template.series[0].data.push(b);
                        });
                        this.retardo_template.title.text = `Empresa: ${arguments[0].data.name}`;
                        this.finaliza_carga_grafica_retardos = true;
                        this.dialog_grafica_retardos = true;
                    })
                    .catch((err) => console.log(err));
                } 
                else if (this.rol == "admin-empresa") {
                    parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        sucursal_id : arguments[0].data.id,
                        rol_name    : "admin-sucursal",
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                    };
                    dashboardApi.getDashboardRetardos(parametros).then((response) => {
                        let format_retardos = response;
                        format_retardos.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.retardo_template.series[0].data.push(b);
                        });
                        this.retardo_template.title.text = `Sucursal: ${arguments[0].data.name}`;
                        this.finaliza_carga_grafica_retardos = true;
                        this.dialog_grafica_retardos = true;
                    })
                    .catch((err) => console.log(err));
                }
            }
        },
        onClickRetardoTemplate() {
            if (this.rol == "admin") {
                this.retardo_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    sucursal_id : arguments[0].data.id,
                    rol_name    : "admin-sucursal",
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                };
                dashboardApi.getDashboardRetardos(parametros).then((response) => {
                    let format_retardos = response;
                    format_retardos.forEach((element) => {
                        let b = {
                            value   : element.length,
                            name    : element[0].nombre,
                            id      : element[0].id,
                        };
                        this.retardo_template2.series[0].data.push(b);
                    });
                    this.retardo_template2.title.text = "Sucursal: " + arguments[0].data.name;
                    this.mostrar_grafica_retardos = true;
                })
                .catch((err) => console.log(err));
            }
        },
        onClickDocumentosTemplate(event, instance, ECharts){
            this.titulo_modal = arguments[0].name;
            if(arguments[0].data.documento.total == 100){
                this.colorDocumento = "#50E18E";
            }
            else if(arguments[0].data.documento.total > 50 && arguments[0].data.documento.total < 100){
                this.colorDocumento = "#FFC85F";
            }
            else{
                this.colorDocumento = "#E75858";
            }

            let parametros = {usuario_id: this.datosLogin.id, id: arguments[0].data.documento.id };
            dashboardApi.getPorcentajeDocumentos(parametros).then((response) => {
                this.datosDocumento = response;
                this.dialog_documentos = true;
            })
            .catch((err) => {
                console.log(err);
            });
        },
        mouseover_documentos(event, chartContext, config){
        },
        mouseout_documentos(event, chartContext, config){
        },
        abrirListaEmpleados(documento){
            if(documento.faltantes.length>0){
                this.datosEmpleados = documento.faltantes;
                this.nombre_sucursal = documento.sucursal;
                this.titulo_modal_empleados = "Lista de empleados con documentación faltante";
                this.dialog_empleados = true;
            }
        },
        cerrarListaEmpleados(){
            this.dialog_empleados = false;
        },
        async update() {
            this.llena_datos();
            await this.update_password(this.user_vuex);
            await userApi.updatePassword(this.usuario).then((response) => {
                userApi.getUsuario(this.usuario.id).then((response) => this.update_cliente(response))
                .catch((err) => console.log(err));
                this.cerrarModal();
                this.respuesta_ok("Se ha cambiado la contraseña correctamente.");
            })
            .catch((err) => this.respuesta_error(err));
        },
        verificar() {
            this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
					if (this.confirmacion == this.nuevo_password) {
						this.password_igual = false;
						this.update();
					} 
					else {
						this.password_igual = true;
					}
				}
			});
        },
        llena_datos() {
            this.usuario.id                 = this.datos.id;
            this.usuario.nombre             = this.datos.nombre;
            this.usuario.apellido_paterno   = this.datos.apellido_paterno;
            this.usuario.apellido_materno   = this.datos.apellido_materno;
            this.usuario.puesto             = this.datos.puesto;
            this.usuario.email              = this.datos.email;
            this.usuario.telefono           = this.datos.telefono;
            this.usuario.celular            = this.datos.celular;
            this.rol_nombre                 = this.datos.rol.display_name;
            this.usuario.rol_id             = this.datos.rol.id;
            this.usuario.primer_inicio_sesion = false;
            this.usuario.password           = this.confirmacion;
            this.user_vuex.email            = this.usuario.email;
            this.user_vuex.password         = this.confirmacion;
            this.usuario.fecha_nacimiento   = "";
            if (this.datos.fecha_nacimiento != null) {
                this.usuario.fecha_nacimiento = this.datos.fecha_nacimiento.replace(
                /^(\d{4})-(\d{2})-(\d{2})$/g,
                "$3/$2/$1"
                );
            }
        },
        cerrarModal() {
            this.dialog_primer_login = false;
            this.primer_login = false;
            this.usuario = {};
            this.dialog_grafica = false;
            this.dialog_grafica_incidencia = false;
            this.dialog_grafica_retardos = false;
            this.dialog_documentos = false;
            this.resetValue();
        },
        resetValue() {
            this.retardo_template.series[0].data = [];
            this.retardo_template2.series[0].data = [];
            this.rotacion_template.series[0].data = [];
            this.rotacion_template2.series[0].data = [];
            this.incidencia_template.series[0].data = [];
            this.incidencia_template2.series[0].data = [];
            this.incidencia_template3.series[0].data = [];
            this.mostrar_grafica = false;
            this.mostrar_grafica_incidencia = false;
            this.mostrar_grafica_incidencia2 = false;
            this.mostrar_grafica_retardos = false;
        },
        tabla_css() {
            /* setTimeout(function() {
                var principal = {
                color: "#738AF8",
                border: 
                    "1px solid rgba(129, 25, 236, 0.16)",
                    "border-radius": "24.5px",
                    "font-family": "Montserrat",
                    "font-size": "24px",
                    "font-weight": "600",
                    "line-height": "20px",
                };

                $(".theme--light.v-text-field--solo > .v-input__control > .v-input__slot").css(principal);
            }, 200); */
        },
        respuesta_error(error) {
            Notify.Alert("Error", error.response.data.error);
        },
        respuesta_ok(msj = "") {
            this.cerrarModal();
            this.dialog = false;
            Notify.Success("Operación exitosa", msj);
        },
    },
    async created() {
        /* this.$emit("comportamiento", false);
        await this.inicio(); */
    },
    async mounted() {
        this.$emit('comportamiento',false);
        await this.inicio();
        const elementScroll = document.querySelector("#chartDocumentos");
        if (elementScroll) {
            const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
            });
        }
    },
    updated() {    
        let tablaAniversarios = document.querySelector('#tablaAniversarios');
        let tablaCumpleanios = document.querySelector('#tablaCumpleanios');

        if(tablaAniversarios){
            document.querySelector('#tablaAniversarios tbody').style.minWidth = "400px";
        }

        if(tablaCumpleanios){
            document.querySelector('#tablaCumpleanios tbody').style.minWidth = "400px";
        }
    },
};
</script>
<style scoped>
    #padre {
        display: table;
        height: 500px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #cambio_pass {
        margin-top: -30px;
    }
    #span_cambio {
        font-family: "Montserrat";
        font-style: normal;
        font-size: 11px;
        color: red;
        font-weight: bold;
        margin-bottom: 30px;
    }
    .echarts {
        width: 100%;
        height: 100%;
    }
    .forma {
        border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
    }
    .piechart {
        position: absolute;
        width: 100%;
        height: 500px;
    }
    .chart_wrap {
        position: relative;
        padding-bottom: 100%;
        height: 0;
        overflow: hidden;
    }
    .espacio {
        padding: 10px;
    }
    .icono {
        color: #2DB9D1;
    }
    .leyenda {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: rgba(16, 28, 90);
    }
    .leyendaPeriodo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #828282;
    }
    .subtitulo {
        color: #96999a;
        font-family: "Montserrat";
        font-style: normal;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: -0.34px;
        padding-left: 35px;
    }
    .empleado {
        color: #204154;
        font-family: "Montserrat";
        font-style: normal;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.34px;
        padding-left: 35px;
    }
    .fecha {
        color: #96999a;
        font-family: "Montserrat";
        font-style: normal;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.43px;
    }
    .puntos {
        color: #d8d8d8;
    }
    .backgroundDash {
        height: 80%;
        width: 80%;
        margin: 5%;
        padding: 5%;
        position: fixed;
        background: url("/static/bg/cliker.png");
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center center;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        display: none;
    }
    .card-titulo-dashboard {
        /* height: 60px;
        align-content: center; */
    }
    .auto-meses {
        height: 10px !important;
    }
    .borde-tabla.v-data-table {
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }

    .v-input__slot .v-select__slot {
        border: 1px solid rgba(30, 34, 69, 0.2) !important;
    }

    .modal-button {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.2px;
        text-decoration: none !important;

        color: #a0a4a8;
        padding-bottom: 24px;
        padding-right: 20px;
        outline: 0;
    }
    .modal-button.save {
        background: transparent !important;
        box-shadow: none;
        color: #007eff;
        margin-bottom: 20px;
        margin-right: 35px;
        text-transform: none !important;
    }

    .v-dialog > .v-card > .v-card__title {
        font-family: "Montserrat" !important;
        font-weight: bold;
        font-size: 18px;
        /*letter-spacing: 0.0125em;*/
        padding: 11px 45px 10px;
    }
    .echart-wrap {
        display: inline-block;
        width: 300px;
        height: 300px;
        overflow-y: auto;
    }
    .div-cargar {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    table.ck-table thead tr {
        height: 35px !important;
    }
    .alto-tr {
        height: 35px !important;
    }
    .sin_documentos {
        margin-top: 90px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #c5c5c5;
    }
    .padding-x50{
		padding-left: 50px !important;
		padding-right: 50px !important;
	}

    .text-mute{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
        color: #57575C;
        align-self: center;
    }
    .subtitle{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #57575C;
        align-self: center;
    }
    .text{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 13px;
        color: #57575C;
        align-self: center;
    }
    .text-empleado{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        color: #1E2245;
        align-self: center;
    }
    .text-departamento{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
        color: #57575C;
        align-self: center;
    }
    .cursor-pointer{
        cursor:pointer;
    }
    #tablaCumpleanios tbody{
        min-width: 400px ;
    }
    #tablaAniversarios tbody{
        min-width: 400px ;
    }
</style>
