export default{
    title: {
        text: "Rotación",
        textStyle: {
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 20,
            color: '#444975'

        },
    },
    tooltip : {
        trigger : "item",
        formatter: function(parms) {
            var str =
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                parms.data.name +
                "</br>" +
                "Total：" +
                parms.data.value +
                "</br>";
                //+"Porcentaje："+ parms.percent+"%";
            return str;
        },
    },
    legend: {
        icon    : "circle",
        orient  : "vertical",
        type    : "scroll",
        bottom : -20,
        left:'left',
        height:150,
        whidth:150,
        itemGap:20,
        textStyle: {
            fontSize    : 14,
            fontWeight  : 500,
            fontFamily  : "Montserrat",
            fontStyle   : "normal",
            color       : "#57575C",
        },
    },
    series: [
        {
            legendHoverLink: false,
            name    : "Rotación",
            type    : "pie",
            radius  : ["50%", "70%"],
            center  : ["50%", "50%"],
            label   : {
                normal  : {
                    show: true,
                    position: "center",
                    formatter: (params) => {
                        return "{top| Rotación}" + "\n" + "{bottom|100%}";
                    },
                    rich: {
                        top: {
                            color       : "#96999A",
                            fontSize    : 14,
                            fontWeight  : 600,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                        },
                        bottom          : {
                            color       : "#96999A",
                            fontSize    : 16,
                            fontWeight  : 800,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                            padding     : [0, 0, 0, 5],
                        },
                    },
                },
                emphasis: {
                    show: true,
                    formatter: (params) => {
                        return ("\n" + "{top| " + params.data.name + "}" + "\n" + "{bottom|" + params.percent + "%}");
                    },
                    rich: {
                        top: {
                            color       : "#96999A",
                            fontSize    : 14,
                            fontWeight  : 600,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                        },
                        bottom          : {
                            color       : "#96999A",
                            fontSize    : 16,
                            fontWeight  : 800,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                            padding     : [0, 0, 0, 5],
                        },
                    },
                },
            },
            labelLine: {
                normal: {
                    show: false,
                },
            },
            data: [],
            itemStyle: {
                normal: {
                    borderWidth: 2,
                    borderColor: "#fff",
                },
            },
            /* color:  [ 
                    '#F7CA18','#F8690E','#FF4E71','#4FD2E0','#17B5FE',
                    '#03E179','#035BFF','#5F5C61','#0A9D0A','#F318A5',
                    '#771919','#14C6B5','#3879F2','#2959B2','#5DB5D6',
                    '#427E98','#264957','#2959B2','#6B4539','#F79F86',
                    '#BBB09F','#7B7469','#633A3B','#F27951','#F08D90',
                    '#BFBFBF','#404040','#C2E860','#839B49','#6D8236',
                    '#9EA884','#DAE8B6','#E6F5C0','#C2CFA3','#646019',
                    '#F1E640','#B1AA2E'
                    ] */
            //color: ['#101C5A','#172674','#2C3E9E','#3C4CA4','#4C5AA6','#54609E','#69729D','#8289AB','#A8ACBE']
            color: [
                "#1E2245",
                "#AEB2D3",
                "#2DB9D1",
                "#9CE7F3",
            ],
        },
    ],
}