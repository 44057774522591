<template>

        <!--contenedor 2-->
        <v-col cols="12" xs="12" sm="12" md="12" class="px-0 py-6"> 
            <v-row>
                <draggable 
                    class="row col-lg-12 pa-0"
                    v-model="list2"
                    :options="{group: { name: 'h1', put: 'h1'}, animation: 300}"
                    @change="onUnpublishedChange"
                    @end="onEnd"
                >
                    <template v-for="element in list2">
                        <v-col 
                            :cols="element.id == 1? '4': element.id == 2 ? '8':'12'" 
                            v-if="element.id != 3" 
                            :key="element.id"
                            v-show="
                                element.id != 3 &&
                                ((element.id == 1 && vuexShowCardContratos) ||
                                (element.id == 2 && vuexShowCardCostoSocial) ||
                                (element.id == 4 && vuexShowCardPagos))
                            " 
                        >
                            <!--contenedor 2-1-->
                                <template v-if="element.id == 1">
                                    <Contratos
                                        :father_fecha_inicio="father_fecha_inicio"
                                        :father_fecha_fin="father_fecha_fin"
                                    >
                                    </Contratos>
                                </template>
                            <!--contenedor 2-1-->

                            <!--contenedor 2-2-->
                                <template v-else-if="element.id == 2">
                                    <CostoLaboral
                                        :father_fecha_inicio="father_fecha_inicio"
                                        :father_fecha_fin="father_fecha_fin"
                                    >
                                    </CostoLaboral>
                                </template>
                            <!--contenedor 2-2-->
                            

                            <!--contenedor 2-3-->
                                <template v-else-if="element.id == 3">
                                    <!-- <Percepciones
                                        :father_fecha_inicio="father_fecha_inicio"
                                        :father_fecha_fin="father_fecha_fin"
                                    >
                                    </Percepciones> -->
                                </template>
                            <!--contenedor 2-3-->
                            <!--contenedor 2-3-->
                                <!-- <template v-else-if="element.id == 3">
                                    <Percepciones
                                        :father_fecha_inicio="father_fecha_inicio"
                                        :father_fecha_fin="father_fecha_fin"
                                    >
                                    </Percepciones>
                                </template> -->
                            <!--contenedor 2-3-->

                            <!--contenedor 2-4-->
                                <template v-else-if="element.id == 4">
                                    <Pagos
                                        :father_fecha_inicio="father_fecha_inicio"
                                        :father_fecha_fin="father_fecha_fin"
                                    >
                                    </Pagos>
                                </template>
                            <!--contenedor 2-4-->
                        </v-col>
                    </template>
                </draggable>
            </v-row>
        </v-col>
        <!--contenedor 2-->
              
                
</template>

<script>
import draggable from "vuedraggable";
import Contratos from '@/components/dashboard/contratos.vue';
import CostoLaboral from '@/components/dashboard/costoLaboral.vue';
import Percepciones from '@/components/dashboard/percepciones.vue';
import Pagos from '@/components/dashboard/pagos.vue';
import { mapState } from "vuex";

export default {
	components: {
        draggable,
        Contratos,
        CostoLaboral,
        Percepciones,
        Pagos
    },
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
	    return {
            list2:[
                {
                    "name": "Contratos",
                    "id": 1,
                    "fixed": false
                },
                {
                    "name": "Costo laboral",
                    "id": 2,
                    "fixed": false
                },
                {
                    "name": "Percepciones",
                    "id": 3,
                    "fixed": false
                },
                {
                    "name": "Pagos",
                    "id": 4,
                    "fixed": false
                },
            ],
			datosLogin	        : null,
			rol			        : '',
            cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
		}
	},
    computed:{
        ...mapState("dashboard", [
            "vuexShowCardCostoSocial",
            "vuexShowCardContratos",
            "vuexShowCardPagos",
        ]),
    },
	methods:{
        onUnpublishedChange({ added }) {
            console.log(added)
        },
        onEnd(sd){
            
            console.log(sd);
            console.log(this.list1);
            console.log(this.list2);
        },
		inicio(){
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }
        },	
    },
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	}, 
}
</script>