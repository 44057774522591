import Vue from 'vue';

export default {
    getDashboardRotacion(parametros){
        return Vue.axios.get("dashboard/rotacion", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDashboardIncidencias(parametros){
        return Vue.axios.get("dashboard/incidencias", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getDashboardRetardos(parametros){
        return Vue.axios.get("dashboard/retardos", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    getPorcentajeDocumentos(parametros){
        return Vue.axios.get("dashboard/getPorcentaje", {params: parametros}).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
}