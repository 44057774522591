<template>
    <div>
        <v-col cols="12" lg="12" class="">
            <draggable 
                class="row" 
                v-model="list1" 
                :options="{ group: { name: 'g1', put: 'g1'}, animation: 300 } " 
                @change="onUnpublishedChange"
                @end="onEnd">

                <!-- <v-col :cols="element.id == 1? '3':'9'" class="py-0" v-for="element in list1" :key="element.id">
                    <template v-if="element.id == 1">
                        <Colaboradores 
                            :father_fecha_inicio="father_fecha_inicio"
                            :father_fecha_fin="father_fecha_fin"
                        >
                        </Colaboradores>
                    </template>
                    <template v-else>
                        <draggableChild
                            :father_fecha_inicio="father_fecha_inicio"
                            :father_fecha_fin="father_fecha_fin"
                        >
                        </draggableChild>
                    </template>
                </v-col>
                -->

                <template v-for="element in list1" >
                    <v-col 
                        :cols="calculoColumnas(element.id)" 
                        v-show="(element.id == 1 && vuexShowCardColaboradores) ||
                            (element.id != 1 && vuexShowCardColaboradores) ||
                            (element.id != 1 && !vuexShowCardColaboradores)
                            " 
                        class="py-0" 
                        :key="element.id"
                    >
                        <template v-if="element.id == 1">
                            <Colaboradores 
                                :father_fecha_inicio="father_fecha_inicio"
                                :father_fecha_fin="father_fecha_fin"
                            >
                            </Colaboradores>
                        </template>
                        <template v-else>
                            <draggableChild
                                :father_fecha_inicio="father_fecha_inicio"
                                :father_fecha_fin="father_fecha_fin"
                            >
                            </draggableChild>
                        </template>
                    </v-col>
                </template>
                

            </draggable>
        </v-col>
    </div>
</template>

<script>
import draggable from "vuedraggable";
import draggableChild from '@/components/dashboard/draggableChild';
import Colaboradores from '@/components/dashboard/colaboradores.vue';
import { mapState } from "vuex";

export default {
	components: {
        draggableChild,
        draggable,
        Colaboradores,
    },
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
            list1:[
                {
                    "name": "Colaboradores",
                    "id": 1,
                    "fixed": false
                },
                {
                    "name": "Graficas",
                    "id": 2,
                    "fixed": false
                },
            ],
			datosLogin	        : null,
			rol			        : '',
            cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
		}
	},
    computed:{
        ...mapState("dashboard", [
            "vuexShowCardColaboradores",
            "vuexShowCardCostoSocial",
            "vuexShowCardContratos",
            "vuexShowCardPagos",
        ]),
        
    },

	methods:{
        calculoColumnas (elementId) {
            if(elementId == 1 && this.vuexShowCardColaboradores){
                return '3'
            }
            if(elementId != 1 && this.vuexShowCardColaboradores){
                return '9'
            }
            if(elementId == 1 && !this.vuexShowCardColaboradores){
                return '1'
            }
            if(elementId != 1 && !this.vuexShowCardColaboradores){
                return '12'
            }
        },
        onUnpublishedChange({ added }) {
            console.log(added)
        },
        onEnd(sd){
            console.log(sd);
            console.log(this.list1);
            console.log(this.list2);
        },
		inicio(){
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }		
		},
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	},
}
</script>
