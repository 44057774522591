<template>
    <div>
        <v-col cols="12" xs="12" sm="12" md="12" class="pa-0" v-if="vuexShowCardContratos">
            <v-card class="borde-card big elevation-0">
                <v-card-text class="pr-5" style="min-height:20vh;">
                    <v-row>
                        <!--Titulo del widget-->
                        <v-col cols="12" class="d-flex px-0">
                            <v-col cols="10" xs="10" sm="10" md="10" class="pa-0">
                                <p class="widget-title">Contratos</p>
                            </v-col>
                            <v-col cols="2" xs="2" sm="2" md="2" class="pa-0">
                                <v-menu bottom left>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            small 
                                            icon 
                                            class="float-right"
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="changeVMenuCss(false)"
                                        >
                                            <v-icon >more_vert</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            v-for="(item, i) in [{title:'Quitar'}]"
                                            :key="i"
                                        >
                                            <v-list-item-title @click="cardAction(item.title,2)" >{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                        </v-col>
                        <!--Titulo del widget-->

                        <!--Contratos-->
                        <v-col cols="12" xs="12" sm="12" md="12" class="d-flex pa-0 mt-5">
                            <v-row>
                                <v-col cols="5" xs="5" sm="5" md="5" class="pa-0">
                                    <img src="static/icon/dashboard/contrato.png" class="col-sm-11 py-0"/>
                                </v-col>
                                <v-col cols="7" xs="7" sm="7" md="7" class="px-0 pr-4 ">
                                    <v-row class="mb-1">
                                        <v-col cols="8" xs="8" sm="8" md="8" class="py-0">
                                            <p class="contrato-title mb-1">Por renovar</p>
                                        </v-col>
                                        <v-col cols="4" xs="4" sm="4" md="4" class="py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">{{contratos.renovar}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">Temporales</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">{{contratos.temporal}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title mb-1">Indefinidos</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0 pr-9">
                                            <p class="float-right contrato-title-num mb-1">{{contratos.indefinido}}</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="contrato-title">Vencidos</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0 pr-9">
                                            <p class="float-right contrato-title-num">{{contratos.vencidos}}</p>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="mt-12 pb-1">
                                            <v-divider class="blue-divider-mute"></v-divider>
                                        </v-col>
                                        
                                        <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                            <p class="colab-total">Total</p>
                                        </v-col>
                                        <v-col cols="6" xs="6" sm="6" md="6" class=" py-0 pr-9">
                                            <p class="float-right colab-total-num">{{contratos.total}}</p>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!--Fin Contratos-->
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import { mapState, mapActions } from "vuex";

export default {
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
			cardColaboradores 	: true,
			cardContratos 		: true,
			cardCostolaboral 	: true,
			cardPercepciones 	: true,
			cardPagos 			: true,
			datosLogin			:'',
			rol					:'',
			cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
            contratos			: {
                total: 0,
                renovar: 0,
                vencidos: 0,
                temporal: 0,
                indefinido: 0
            }
		}
	},
    computed: {
        ...mapState("dashboard", ["dashboardContratos","vuexShowCardContratos"])
	},
    watch:{
        father_fecha_inicio:function(newValue, oldValue){
            this.inicio()
        },
        father_fecha_fin:function(newValue, oldValue){
            this.inicio()
        },
        dashboardContratos:function(newVal, oldVal){
            if(newVal){
                this.contratos = newVal
            }
        },
        vuexShowCardContratos:function(newVal, oldVal){
            this.cardContratos = newVal
        }
    },
	methods:{
        ...mapActions("dashboard", ['setVuexShowCardContratos']),
		inicio(){

        },
		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						this.cardColaboradores = false;
						break;
					case 2:
						//this.cardContratos = false;
                        this.setVuexShowCardContratos(false)
						break;
					case 3:
						this.cardCostolaboral = false;
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						this.cardPagos = false;
						break;	
					case 'restaurar':
						this.cardColaboradores = true;
						this.cardContratos = true;
						this.cardCostolaboral = true;
						this.cardPercepciones = true;
						this.cardPagos = true;
						break;
				}
					
				
			}else if(type == "Mover"){

			}
		},
        changeVMenuCss(bool){
            if(bool){
                setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.add("drop");
                    }
                },50);
                
            }else{
                 setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.remove("drop");
                    }
                },200);
            }
        },
		
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	}, 
}
</script>

<style scoped>
	.blue-divider-mute{
		border: 1px solid rgba(45, 185, 209, 0.1);
	}
	.colab-total{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.colab-total-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.contrato-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #828282;
	}
	.contrato-title-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.widget-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #444975;
	}
    .v-menu__content{
        border-radius: 15px;
        margin-top: 28px;
		width: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

	.drop{
        margin-top: 48px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
</style>