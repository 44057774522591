import gql from 'graphql-tag';

const timbresTableQuery = gql`
    query timbres($whereConditions: TimbresWhereWhereConditions,$numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        timbres(where:$whereConditions, first: $numberItems, page: $numberPage, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id
                cliente_id,
                empresa_id,
                sucursal_id,
                nomina_id,
                empleado_id,
                uuid,
                serie,
                razon_social_emisor,
                razon_social_emisor,
                rfc_receptor,
                emision,
                timbrado,
                url_timbre,
                cancelado,
                errores,
                retimbrado,
                tipo_indemnizacion,
                clave_motivo_cancelacion,
                cliente{
                    nombre
                },
                empresa{
                    nombre
                },
                sucursal{
                    nombre
                },
                empleado{
                    nombre_completo,
                    foto
                },
                nomina{
                    id,
                    tipoNomina{
                        clave
                    }
                    periodo{
                        numero,
                        descripcion,
                        descripcion_fecha,
                        fecha_final,
                        tipo_periodo_especial
                    }  
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { timbresTableQuery };

export default queries;
