<template>
    <div>
        <!--contenedor 1-->
            <v-col cols="12" xs="12" sm="12" md="12" class="pr-3 pl-0" v-if="vuexShowCardColaboradores">
                <v-card class="borde-card big elevation-0">
                    <v-card-text class="pr-5" style="min-height:77.5vh;">
                        <v-row>
                            <!--Titulo del widget-->
                            <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                                <v-col cols="10" xs="10" sm="10" md="10" class="pa-0">
                                    <p class="widget-title">Colaboradores</p>
                                </v-col>
                                <v-col cols="2" xs="2" sm="2" md="2" class="pa-0">
                                    <v-menu bottom left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                small 
                                                icon 
                                                class="float-right"
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="changeVMenuCss(false)"
                                            >
                                                <v-icon >more_vert</v-icon>
                                            </v-btn>
                                        </template>

                                        <v-list>
                                            <v-list-item
                                                v-for="(item, i) in [{title:'Quitar'}]"
                                                :key="i"
                                            >
                                                <v-list-item-title @click="cardAction(item.title,1)" >{{ item.title }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </v-col>
                            </v-col>
                            <!--Titulo del widget-->
                            
                            <!--Total global-->
                            <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
                                        <img src="static/icon/dashboard/colaboradores.png" class="col-sm-6 py-0"/>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0 pr-9">
                                        <p class="float-right colab-total mb-0">Total</p>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class=" py-0 pr-9">
                                        <p class="float-right colab-total-num">{{total}}</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!--Total global-->

                            <!--Nuevos ingresos-->
                            <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <p class="colab-sub-title mb-2">Nuevos ingresos</p>
                                        <div class="d-flex" style="height:20px;" v-if="showIngresos">
                                            <hr class="vrC"/><p class="subTotal">Total: {{totalIngresos}}</p>
                                        </div>
                                    </v-col>
                                    <v-col v-if="showIngresos" cols="12" class="pr-9" style="margin-top:-180px; z-index:2;">
                                        <v-chart
                                            style="width:100%; min-height:600px; position:relative; margin-bottom:0px"
                                            :options="pieNuevosIngresos"
                                            :autoresize="true"
                                            @click="onClickRotacion"
                                            @mouseover="mouseover_nuevosIngresos01"
                                            @mouseout="mouseout_nuevosIngresos01"  
                                        />
                                    </v-col>
                                    <v-col v-if="!showIngresos" cols="12" class="pr-9" style=" z-index:2;">
                                        <div slot="widget-content" id="chartContainer" style="height:300px; width:100%; margin-bottom: 20px;">
                                            <v-chart
                                                style="width:100%; height:300px; position:relative;"
                                                :options="pieSinDatos"
                                                :autoresize="true"
                                            />
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!--Fin Nuevos ingresos-->

                            <v-col ols="12" xs="12" sm="12" md="12" class="px-0 pr-5 pb-5 pt-2">
                                <v-divider class="blue-divider"></v-divider>
                            </v-col>

                            <!--Rotación-->
                            <v-col cols="12" xs="12" sm="12" md="12" class="d-flex px-0">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                        <p class="colab-sub-title mb-2">Rotación</p>
                                        <div class="d-flex" v-if="showRotacion" style="height:20px;">
                                            <hr class="vrC"/><p class="subTotal">Total: {{totalRotacion}}</p>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" v-if="showRotacion" class="pr-9" style="margin-top:-180px; z-index:1;">
                                        <v-chart
                                            style="width:100%; min-height:600px; position:relative; margin-bottom:0px"
                                            :options="rotacion"
                                            :autoresize="true"
                                            @click="onClickRotacion"
                                            @mouseover="mouseover_rotacion01"
                                            @mouseout="mouseout_rotacion01"
                                        />
                                    </v-col>
                                    <v-col v-if="!showRotacion" cols="12" class="pr-9" style="; z-index:1;">
                                        <v-chart
                                            style="width:100%; min-height:300px; position:relative; margin-bottom:0px"
                                            :options="pieSinDatos"
                                            :autoresize="true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!--Fin Rotación-->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        <!--contenedor 1-->

        <template>
            <v-row justify-center>
                <v-dialog v-model="dialog_grafica" persistent max-width="400px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            slot="activator"
                            class="botonModal"
                            id="btnModal"
                        ></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <v-card-text>
                                <v-container grid-list-md>
                                    <v-row wrap>
                                        <v-col
                                        lg="12"
                                        sm="12"
                                        xs="12"
                                        v-if="rol != 'empleado'"
                                        >
                                            <div
                                                slot="widget-content"
                                                id="chartContainer"
                                                style="height:350px; width:100%; padding:0px;"
                                            >
                                                <v-chart
                                                    style="width:100%; height:350px; position:relative;"
                                                    :options="rotacion_template"
                                                    :autoresize="true"
                                                    @click="onClickRotacionTemplate"
                                                    @mouseover="mouseover_rotacion02"
                                                    @mouseout="mouseout_rotacion02"
                                                />
                                            </div>
                                        </v-col>
                                        <v-col lg="12" sm="12" xs="12" v-if="mostrar_grafica">
                                            <v-divider
                                                :inset="inset"
                                                class="espacio"
                                            ></v-divider>
                                            <div
                                                slot="widget-content"
                                                id="chartContainer"
                                                style="height:350px; width:100%;"
                                            >
                                                <v-chart
                                                    style="width:100%; height:350px; position:relative;"
                                                    :options="rotacion_template2"
                                                    :autoresize="true"
                                                    @mouseover="mouseover_rotacion03"
                                                    @mouseout="mouseout_rotacion03"
                                                />
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="#007EFF"
                                    class="mr-8"
                                    text
                                    @click="cerrarModal()"
                                >
                                    Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-row>
        </template>
    </div>
</template>

<script>
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import dashboardApi from "@/api/dashboard";
import pieSinDatos from "./pieSinDatos";
import pieNuevosIngresos from "./pieNuevosIngresos";
import rotacion from "./rotacion";
import rotacion_template from "./rotacion_template";
import rotacion_template2 from "./rotacion_template2";
import { mapState, mapActions } from "vuex";



export default {
    props:{
        father_fecha_inicio: {
            type: String
        },
        father_fecha_fin:{
            type: String
        }
    },
	data() {
		return {
            showRotacion : false,
            showIngresos : false,
			cardColaboradores : true,
			datosLogin	:'',
			rol			:'',
			
			min_fecha : null,
			max_fecha : null,
            fecha_inicio : null,
            fecha_fin : null,
			dialog_grafica : false,
			mostrar_grafica : false,
            pieSinDatos, // datos para una grafica tipo dona "Sin datos"
			rotacion, //datos para la grafica tipo dona "Rotación"
			rotacion_template, //datos para la grafica tipo dona "Rotación" para el modal
 			rotacion_template2,//datos para la grafica tipo dona "Rotación" para el modal
			pieNuevosIngresos, // datos para la grafica tipo dona " Nuevos ingresos"
            total: 0,
            totalIngresos: 0,
            totalRotacion: 0,
		}
	},
    computed: {
        ...mapState("dashboard", [
            "vuexColaboradoresIngresos",
            "vuexColaboradoresRotacion",
            "vuexColaboradoresTotal",
            "vuexColaboradoresTotalIngresos",
            "vuexColaboradoresTotalRotacion",
            "vuexColaboradoresShowIngresos",
            "vuexColaboradoresShowRotacion",
            "vuexShowCardColaboradores"
        ])
	},
    watch:{
        father_fecha_inicio:function(newValue, oldValue){
            this.inicio()
        },
        father_fecha_fin:function(newValue, oldValue){
            this.inicio()
        },
        vuexColaboradoresIngresos:function(newValue, oldValue){
            this.pieNuevosIngresos.series[0].data = newValue
        },
        vuexColaboradoresRotacion:function(newValue, oldValue){
            this.rotacion.series[0].data = newValue
        },
        vuexColaboradoresTotal:function(newValue, oldValue){
            this.total = newValue
        },
        vuexColaboradoresTotalIngresos:function(newValue, oldValue){
            this.totalIngresos = newValue
        },
        vuexColaboradoresTotalRotacion:function(newValue, oldValue){
            this.totalRotacion = newValue
        },
        vuexColaboradoresShowIngresos:function(newValue, oldValue){
            this.showIngresos = newValue;
        },
        vuexColaboradoresShowRotacion:function(newValue, oldValue){
            this.showRotacion = newValue;
        },
        vuexShowCardColaboradores:function(newVal, oldVal){
            this.cardColaboradores = newVal
        }

    },
	methods:{
        ...mapActions("dashboard", ['setVuexShowCardColaboradores']),
		inicio(){

            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

			let parametrosDashboard = {};
            if (this.rol != "admin-depto") {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            } 
            else {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    fecha_inicio: this.father_fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.father_fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            }

            /*
            this.pieNuevosIngresos.series[0].data = []
            this.rotacion.series[0].data = []

			if (this.rol != "root") {
                empleadosApi.getDashboardNominas(parametrosDashboard).then(async(response) => {
                    this.pieNuevosIngresos.series[0].data = []
                    this.rotacion.series[0].data = []
                    this.total          = response.empleados.total;
                    this.totalIngresos  = response.empleados.nuevos;
                    this.totalRotacion  = response.empleados.bajas;
                    

                    let sin_ingresos = 0;
                    let format_desglose = response.empleados.desglose;
                    await format_desglose.forEach((element) => {
                        if (element["total"] == 0) {
                            sin_ingresos++;
                            if (sin_ingresos == format_desglose.length) {
                                let b = {
                                    value: 1,
                                    name: "Sin ingresos",
                                };
                                this.pieNuevosIngresos.series[0].data.push(b);
                            }
                        }
                        if (element["total"] != 0) {
                            let b = {
                                value: element["total"],
                                name: element["nombre"],
                            };
                            this.pieNuevosIngresos.series[0].data.push(b);
                        }

                        this.showIngresos = true;
                    });
                    let sin_rotacion = 0;
                    let format_rotacion = response.empleados.rotacion;
                    if (this.rol == "admin" || "admin-empresa" || "admin-sucursal") {
                        if (format_rotacion.length == 0 || format_rotacion == undefined) {
                            //this.showIngresos = false;
                            //this.showRotacion = false;
                            //let b = [{value: 1, name: "Sin datos"}];
                            //this.rotacion.series[0].data = b;
                        } 
                        else {
                            await format_rotacion.forEach((element) => {
                                let b = {
                                    value: element["total"],
                                    name: element["nombre"],
                                };
                                this.rotacion.series[0].data.push(b);
                                this.showRotacion = true;
                            });
                        }
                    }
                })
                .catch(err => {
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            } */
		},
        changeVMenuCss(bool){
            if(bool){
                setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.add("drop");
                    }
                },50);
                
            }else{
                 setTimeout(()=>{
                    let menu = document.querySelectorAll(".v-menu__content");
                    for(let item of menu){
                        item.classList.remove("drop");
                    }
                },200);
            }
        },
		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						//this.cardColaboradores = false;
                        this.setVuexShowCardColaboradores(false)
						break;
					case 2:
						this.cardContratos = false;
						break;
					case 3:
						this.cardCostolaboral = false;
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						this.cardPagos = false;
						break;	
					case 'restaurar':
						this.cardColaboradores = true;
						this.cardContratos = true;
						this.cardCostolaboral = true;
						this.cardPercepciones = true;
						this.cardPagos = true;
						break;
				}
					
				
			}else if(type == "Mover"){

			}
		},

		//GRAFICAS
		mouseover_rotacion01(event, chartContext, config) {
            this.rotacion.series[0].label.normal.show = false;
        },
        mouseout_rotacion01(event, chartContext, config) {
            this.rotacion.series[0].label.normal.show = true;
        },
		mouseover_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = false;
        },
        mouseout_rotacion02(event, chartContext, config) {
            this.rotacion_template.series[0].label.normal.show = true;
        },
		mouseover_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = false;
        },
        mouseout_rotacion03(event, chartContext, config) {
            this.rotacion_template2.series[0].label.normal.show = true;
        },
		mouseover_nuevosIngresos01(event, chartContext, config) {
            this.pieNuevosIngresos.series[0].label.normal.show = false;
        },
        mouseout_nuevosIngresos01(event, chartContext, config) {
            this.pieNuevosIngresos.series[0].label.normal.show = true;
        },
		onClickRotacion(event, instance, ECharts) {
            return //return no se tiene datos para el click
            if (arguments[0].data.name != "Sin rotación") {
                this.finaliza_carga_grafica = false;
                let parametros = null;
                if (this.rol == "admin") {
                    parametros = {
                        rol_name: this.datosLogin.rol.name,
                        usuario_id: this.datosLogin.id,
                        empresa_id: arguments[0].data.id,
                        rol_name: "admin-empresa",
                        min_fecha: this.min_fecha,
                        max_fecha: this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
						debugger;
                        let format_rotacion = response;
						if (format_rotacion.length == 0 || format_rotacion == undefined) {
							for(let i=0;i<2;i++){
								let b = { value: (i+1), name: "Sin rotación skjdf ldk fn"+i,id: (i+1) };
								this.rotacion_template.series[0].data.push(b);
							}
						}else{
							format_rotacion.forEach((element) => {
								let b = {
									value: element.length,
									name: element[0].nombre,
									id: element[0].id,
								};
								this.rotacion_template.series[0].data.push(b);
							});
						}
                        //this.rotacion_template.title.text = `Empresa: ${arguments[0].data.name}`;
                        this.rotacion_template.title.text = `Ingresos por sucursal`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                } 
                else if (this.rol == "admin-empresa") {
                    parametros = {
                        rol_name    : this.datosLogin.rol.name,
                        usuario_id  : this.datosLogin.id,
                        sucursal_id : arguments[0].data.id,
                        rol_name    : "admin-sucursal",
                        min_fecha   : this.min_fecha,
                        max_fecha   : this.max_fecha,
                    };
                    dashboardApi.getDashboardRotacion(parametros).then((response) => {
                        let format_rotacion = response;
                        format_rotacion.forEach((element) => {
                            let b = {
                                value: element.length,
                                name: element[0].nombre,
                                id: element[0].id,
                            };
                            this.rotacion_template.series[0].data.push(b);
                        });
                        //this.rotacion_template.title.text = `Sucursal: ${arguments[0].data.name}`;
                        this.rotacion_template.title.text = `Ingresos por departamento`;
                        this.finaliza_carga_grafica = true;
                        this.dialog_grafica = true;
                    })
                    .catch((err) => console.log(err));
                }
            }
        },
		onClickRotacionTemplate() {
            if (this.rol == "admin") {
                this.rotacion_template2.series[0].data = [];
                let parametros = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    sucursal_id : arguments[0].data.id,
                    rol_name    : "admin-sucursal",
                    min_fecha   : this.min_fecha,
                    max_fecha   : this.max_fecha,
                };
                dashboardApi.getDashboardRotacion(parametros).then((response) => {
                    let format_rotacion = response;
                    format_rotacion.forEach((element) => {
                        let b = {
                            value: element.length,
                            name: element[0].nombre,
                            id: element[0].id,
                        };
                        this.rotacion_template2.series[0].data.push(b);
                    });
                    //this.rotacion_template2.title.text = "Sucursal: " + arguments[0].data.name;
                    this.rotacion_template2.title.text = `Ingresos por departamento`;
                    this.mostrar_grafica = true;
                })
                .catch((err) => console.log(err));
            }
        },
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
		await this.inicio();
	},
}
</script>

<style scoped>
	.blue-divider{
		opacity: 0.7;
		border: 1px solid #2DB9D1;
	}
	.colab-sub-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #636997;
	}
	.colab-total{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.colab-total-num{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.01em;
		color: #444975;
	}
	.widget-title{
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.01em;
		color: #444975;
	}
    .v-menu__content{
        border-radius: 15px;
        margin-top: 28px;
		width: 150px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }

    .subTotal{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: #636997;
    }

    .vrC{
        margin-top:-5px;
        border: 0.1px solid #AEB2D3;
        background-color: #AEB2D3;
        border-radius: 10px;
        height: 100% !important;
        margin-right: 10px;
    }
</style>