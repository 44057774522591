export default{
    tooltip: {
        trigger: "item",
        axisPointer: {
            type: "line",
            lineStyle: {
                width:60,
                color:"rgba(14, 248, 254,.1)"
            }
        },
        textStyle:{
            color:"#636997",
            fontSize: 12
        },
        legend: {
            show: true,
            right: 10,
            itemWidth: 4,
            itemHeight: 12,
            itemGap: 15,
            icon: "circle",
        },
        borderWidth: 1,
        backgroundColor: "#FFFFFF",
        borderColor: "#EFEFEF",
        formatter: function(parms) {
            var str =
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${parms.color};"></span>` +
                parms.name +
                "</br>" +
                "Total：" +
                parms.value +
                "</br>";
                //+"Porcentaje："+ parms.percent+"%";
            return str;
        },
        extraCssText: "z-index:96"
    },
    xAxis: {
        type: 'category',
        //data: ['Sueldo bruto', 'Comisiones', 'Aguinaldo', 'Vacaciones', 'Horas extras dobles', 'Horas extras triples']
        data:[],
        axisLine:{
            show:true
        }

    },
    yAxis: {
        type: 'value',
        splitLine: {
            show: true, //borrar las lineas dentro de la grafica "false"
            lineStyle:{
                color:"#F2F2F2",
                type:'dashed'
            }
        },
        axisLine:{
            show:true
        }
    },
    grid: {
        right: '4%',
        left: '10%',
        bottom: '8%',
        top:'4%'
    },
    
    /* legend:{
        icon    : "circle",
         orient  : "vertical",
        type    : "scroll",
        right:110,
        height:250,
        whidth:150,
        top:100,
        itemGap:20,
        textStyle: {
            fontSize    : 14,
            fontWeight  : 500,
            fontFamily  : "Montserrat",
            fontStyle   : "normal",
            color       : "#57575C",
        },
        data: ['Sueldo bruto', 'Comisiones', 'Aguinaldo', 'Vacaciones', 'Horas extras dobles', 'Horas extras triples']
    }, */
    dataZoom: [
        {
            type: 'inside'
        }
    ],
    series: [
        {
            name:'Pagos',
            type:'bar',
            //data:[15.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 13.3],
            data:[],
            itemStyle: {
                normal: {
                    barBorderRadius: [10, 10, 0, 0],
                    color: function(params) {
                        // build a color map as your need.
                        var colorList = [
                          '#2DB9D1','#2DA2C6','#2D8CBC','#2E75B2','#2E5EA7',
                           '#2E489D','#2E3192','#473386','#60347B','#79366F',
                           '#913863','#AA3958'
                        ];
                        return colorList[params.dataIndex]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{c}'
                    }
                }
            }
        }
    ],
}