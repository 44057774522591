export default {
    series: [
        {
            legendHoverLink: true,
            name    : "Sin datos",
            type    : "pie",
            radius  : ["50%", "70%"],
            center  : ["50%", "50%"],
            label   : {
                normal  : {
                    show: true,
                    position: "center",
                    formatter: (params) => {
                        return "{top| Total}" + "\n" + "{bottom|0%}";
                    },
                    rich: {
                        top: {
                            color       : "#96999A",
                            fontSize    : 14,
                            fontWeight  : 600,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                        },
                        bottom          : {
                            color       : "#96999A",
                            fontSize    : 16,
                            fontWeight  : 800,
                            fontFamily  : "Montserrat",
                            fontStretch : "normal",
                            fontStyle   : "normal",
                            letterSpacing: "normal",
                            padding     : [0, 0, 0, 5],
                        },
                    },
                },
            },
            labelLine: {
                normal: {
                    show: false,
                },
            },
            data: [{value: 0, name: "Sin datos"}],
            itemStyle: {
                emphasis: {
                    
                    color: "#EFEFEF",
                },
            },
            color: [
                "#EFEFEF"
            ],
        },
    ],
}