<template>
	<div>
		<v-container class="backgroundDash" v-if="rol == 'root' || tipoDashboard == 'comidas'">
			<div>  </div>
            <template v-if="primer_login">
                <v-row justify-center>
                    <v-dialog
                        v-model="dialog_primer_login"
                        persistent
                        max-width="420px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                        </template>
                        <v-card>
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="padding-x50 pt-9 pb-8">
                                    <span class="titleModal" v-text="titulo_modal"></span>
                                </v-card-title>
                                <v-card-text class="card_texto">
                                    <v-container grid-list-md key="unique"> 
                                        <v-row class="align-center justify-center"><span id="span_cambio">Por políticas, es requerido cambiar la contraseña.</span></v-row>
                                        <v-row class="pt-4">
                                            <v-col cols="12" xs="12" sm="12" md="12" class="my-0 pa-0">
                                                <ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required|verify_password">
                                                    <v-text-field
                                                        outlined
                                                        label="Nueva contraseña"
                                                        v-model="nuevo_password"
                                                        id="nuevo_password"
                                                        class="py-0"
                                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="show1 = !show1"
                                                        :type="show1 ? 'text' : 'password'"
                                                        auto
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="my-0 pa-0">
                                                <ValidationProvider v-slot="{ errors }" name="Confirmación" rules="required|verify_password">
                                                    <v-text-field
                                                        outlined
                                                        label="Confirmar la contraseña"
                                                        v-model="confirmacion"
                                                        id="verificar_password"
                                                        class="py-0"
                                                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                        @click:append="show2 = !show2"
                                                        :type="show2 ? 'text' : 'password'"
                                                        auto
                                                        @keyup.enter="verificar('confirmacion')"
                                                        :error-messages="errors" 
                                                        required
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <div
                                                v-if="password_igual"
                                                class="v-stepper__label"
                                                style="color:#FF5454; padding-left: 3%; margin-top: -5%;"
                                            >
                                                Las contraseñas no son iguales, favor de verificar.
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion pt-0">
                                    <v-btn color="btnGuardar" @click="verificar()">Guardar</v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-dialog>
                </v-row>
            </template>
		</v-container>
		<v-container v-else xl fluid>
			<v-row wrap>
				<v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-overlay :value="overlayDescarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
				<v-col cols="12" lg="12" class="center">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p v-if="rol == 'empleado'" class="tblName">¡Hola, que tengas un excelente día!</p>
                        <p v-else class="tblName">Bienvenido</p>
                    </div>
                    <template v-if="rol == 'empleado'">
                        <v-row v-show="!overlay">
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-3">
                                <v-card height=43.5em class="elevation-0" style="border-radius: 15px;">
                                    <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                                        <div class="headerModal-filter">
                                            <h2 class="leyenda">Mis logros</h2>
                                        </div>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container grid-list-md id="contenedor">
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-list-item :class="disabledLogro('Puntualidad')">
                                                    <v-list-item-avatar
                                                        tile
                                                        size="80"
                                                    >
                                                        <v-img src="/static/icon/dashboard/misLogros-PuntualidadAsistencia.svg"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="textMisLogros mb-1 pl-4">
                                                            Puntualidad y asistencia
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="pb-2"></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-list-item class="pb-2" :class="disabledLogro('Clima laboral')">
                                                    <v-list-item-avatar
                                                        tile
                                                        size="80"
                                                    >
                                                        <v-img src="/static/icon/dashboard/misLogros-ClimaLaboral.svg"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="textMisLogros mb-1 pl-4">
                                                            Clima laboral
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="pb-2"></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-list-item class="pb-2" :class="disabledLogro('Antigüedad')">
                                                    <v-list-item-avatar
                                                        tile
                                                        size="80"
                                                    >
                                                        <v-img src="/static/icon/dashboard/misLogros-Antiguedad.svg"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="textMisLogros mb-1 pl-4">
                                                            Antigüedad
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="pb-2"></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-list-item class="pb-2" :class="disabledLogro('Proyectos')">
                                                    <v-list-item-avatar
                                                        tile
                                                        size="80"
                                                    >
                                                        <v-img src="/static/icon/dashboard/misLogros-ProyectosMejoras.svg"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="textMisLogros mb-1 pl-4">
                                                            Proyectos y mejoras
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-divider class="pb-2"></v-divider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                                <v-list-item class="pb-2" :class="disabledLogro('Capacitación')">
                                                    <v-list-item-avatar
                                                        tile
                                                        size="80"
                                                    >
                                                        <v-img src="/static/icon/dashboard/misLogros-Capacitacion.svg"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="textMisLogros mb-1 pl-4">
                                                            Capacitación
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-col>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-3 px-0">
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0">
                                    <v-card height=21em class="elevation-0" style="border-radius: 15px;">
                                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                                            <div class="headerModal-filter d-flex">
                                                <h2 class="leyenda">Últimos recibos</h2>
                                                <!-- <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        Para más información sobre tus recibos, visita la sección <br>
                                                        de <span class="linkTooltip">Mis recibos de nómina</span> en el Detalle de empleado o <br>
                                                        bien, dirigirte a la sección desde el menú principal.
                                                    </span>
                                                </v-tooltip> -->
                                                <v-tooltip v-model="showTooltip" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' @click="showTooltip = !showTooltip" class="mb-7 ml-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <template v-slot:default>
                                                        <span class="pb-7 textTooltip" style="pointer-events: initial;">
                                                            Para más información sobre tus recibos, visita la sección <br>
                                                            de <span class="linkTooltip" @click="irDetalle()">Mis recibos de nómina</span> en el Detalle de empleado o <br>
                                                            bien, dirigirte a la sección desde el menú principal.
                                                        </span>
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                        </v-card-title>
                                        <perfect-scrollbar style="background: #fff; overflow:auto;">
                                            <v-card-text class="card_texto" >
                                                <div
                                                    slot="widget-content"
                                                    id="misIncidencias"
                                                    style="height:140px; width:100%; margin-bottom: 20px;"
                                                >
                                                    <div class="row col-lg-12 emptyItems align-center justify-center text-center" v-if="itemsTimbres.length == 0">
                                                        <p class="col-12py-0 px-0">No hay elementos para mostrar</p>
                                                    </div>    
                                                    
                                                    <template v-else>
                                                        <template v-for="(detalle, index) in itemsTimbres">
                                                            <v-row :key="index">
                                                                <v-col class="d-flex align-center" cols="12" xs="6" sm="6" md="6" lg="8" xl="8">
                                                                    <span class="textCard">
                                                                        {{ detalle.nomina.periodo.numero }} - {{detalle.nomina.periodo.descripcion_fecha}}
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="12" xs="6" sm="6" md="6" lg="4" xl="4">
                                                                     <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                v-on="on"
                                                                                fab
                                                                                small
                                                                                color="#FFFFFF"
                                                                                @click="descargarTimbre(detalle,false,'pdf')"
                                                                                elevation="0"
                                                                            >
                                                                                <v-icon color="#96999A" class="colorMdiFilePdf">mdi-file-pdf-outline</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>PDF</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                v-on="on"
                                                                                fab
                                                                                small
                                                                                color="#FFFFFF"
                                                                                @click="descargarTimbre(detalle,false,'xml')"
                                                                                elevation="0"
                                                                            >
                                                                                <v-icon color="#96999A" class="colorMdiFileXml">mdi-file-xml</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>XML</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                v-on="on"
                                                                                fab
                                                                                small
                                                                                color="#FFFFFF"
                                                                                @click="descargarTimbre(detalle,false,'zip')"
                                                                                elevation="0"
                                                                            >
                                                                                <v-icon color="#96999A" class="colorMdiZip">mdi-zip-box</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>ZIP</span>
                                                                    </v-tooltip>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </template>
                                                </div>
                                            </v-card-text>
                                        </perfect-scrollbar>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pb-0 pt-6">
                                    <v-card height=21em class="elevation-0" style="border-radius: 15px;">
                                        <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                                            <div class="headerModal-filter d-flex">
                                                <h2 class="leyenda">Mis incidencias</h2>
                                                <!-- <v-tooltip top class="pb-0" v-model="showTooltip">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" @click="showTooltip = !showTooltip" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-0 textTooltip">
                                                        Para más información sobre tus recibos, visita la sección <br>
                                                        de <span class="linkTooltip">Mis recibos de nómina</span> en el Detalle de empleado o <br>
                                                        bien, dirigirte a la sección desde el menú principal.
                                                    </span>
                                                </v-tooltip> -->
                                                <v-tooltip v-model="showTooltip2" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' @click="showTooltip2 = !showTooltip2" class="mb-7 ml-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <template v-slot:default>
                                                        <span class="pb-0 textTooltip" style="pointer-events: initial;">
                                                            Si deseas saber más sobre tus incidencicas, puedes <br>
                                                            hacer clic sobre este link <span class="linkTooltip" @click="irIrncidencias()">Incidencias</span> o bien,<br>
                                                            dirigirte a la sección desde el menú principal.   
                                                        </span>  
                                                    </template>
                                                </v-tooltip>
                                            </div>
                                        </v-card-title>
                                        <perfect-scrollbar style="background: #fff; overflow:auto;">
                                            <v-card-text class="card_texto" >
                                                <div
                                                    slot="widget-content"
                                                    id="misIncidencias"
                                                    style="height:140px; width:100%; margin-bottom: 20px;"
                                                >
                                                    <div class="row col-lg-12 emptyItems align-center justify-center text-center" v-if="itemsIncidencias.length == 0">
                                                        <p class="col-12py-0 px-0">No hay elementos para mostrar</p>
                                                    </div>
                                                    <template v-else>
                                                        <template v-for="(item, index) in itemsIncidencias">
                                                            <v-row :key="index">
                                                                <v-col class="d-flex align-center" cols="12" xs="6" sm="6" md="6" lg="8" xl="8">
                                                                    <span class="textCard">
                                                                        {{ item.nombre_tipo }}
                                                                    </span>
                                                                </v-col>
                                                                <v-col cols="12" xs="6" sm="6" md="6" lg="4" xl="4">
                                                                    <v-chip
                                                                        class="mx-0 px-0 tipoLetra"
                                                                        color="transparent"
                                                                        outlined
                                                                        :text-color="getColorTextoStatus(item.valor_estatus)"
                                                                    >
                                                                        <img
                                                                            v-if="item.valor_estatus === 'estatus_aprobado'"
                                                                            style="height: 20px; width: 20px;  background-color: transparent;"
                                                                            src="/static/icon/aprovado.png"
                                                                            alt="Empleado"
                                                                        />
                                                                        <img
                                                                            v-else-if="item.valor_estatus === 'estatus_rechazado'"
                                                                            style="height: 20px; width: 20px;  background-color: transparent;"
                                                                            src="/static/icon/cancelado.png"
                                                                            alt="Empleado"
                                                                        />
                                                                        <img
                                                                            v-else-if="item.valor_estatus === 'estatus_pendiente'"
                                                                            style="height: 20px; width: 20px;  background-color: transparent;"
                                                                            src="/static/icon/pendiente.png"
                                                                            alt="Empleado"
                                                                        />
                                                                        <img
                                                                            v-else
                                                                            style="height: 20px; width: 20px;  background-color: transparent;"
                                                                            src="/static/icon/revision.png"
                                                                            alt="Empleado"
                                                                        />
                                                                        <span class="pl-2 textChip">
                                                                            {{item.nombre_estatus }}
                                                                        </span>
                                                                    </v-chip>
                                                                </v-col>
                                                            </v-row>
                                                        </template>
                                                    </template>
                                                    
                                                </div>
                                            </v-card-text>
                                        </perfect-scrollbar>
                                    </v-card>
                                </v-col>
                            </v-col>

                            <!-- TABLE DE CUMPLEAÑOS -->
                            <template>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-3">
                                    <v-card class="borde-card" elevation="0">
                                        <v-card-title class="pb-3 pl-8 pt-8 pr-8">
                                            <v-col cols="12" xs="6" sm="7" md="8" lg="8" xl="9" class="pa-0">
                                                <v-icon class="icono outlined_v_icon">cake</v-icon>
                                                <!-- <i class="material-icons-outlined icono">cake</i> -->
                                                <label class="leyenda" for="">&nbsp;&nbsp;Cumpleaños</label>
                                            </v-col>
                                            <v-col cols="12" xs="6" sm="5" md="4" lg="4" xl="3" class="pa-0 dashboard-inputs2">
                                                <v-select
                                                    :items="meses_c"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    hide-details
                                                    v-model="mes_selecionado"
                                                    outlined
                                                    dense
                                                    class="ma-0 pa-0"
                                                    append-icon="keyboard_arrow_down"
                                                ></v-select>
                                            </v-col>
                                        </v-card-title>
                                        <v-card-text class="pa-0">
                                            <simple-table
                                            :classes="tableClasses"
                                            :rows="itemsEmpleados"
                                            :columns="headersCumpleanios"
                                            :perPage="[5, 25, 50, 100]"
                                            shortPagination
                                            :idTabla="'tablaCumpleanios'"
                                            >
                                            <tbody slot="body" slot-scope="{ data }">
                                                <tr :key="item.id" v-for="item in data">
                                                <td>
                                                    <div class="tblPrincipal">
                                                    {{ item.nombre_completo }}
                                                    </div>
                                                    <div class="tblSecundario">{{ item.nombre_depto }}</div>
                                                </td>
                                                <td>
                                                    <div>{{ item.cumpleanios }}</div>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>

                            <!-- TABLE DE ANIVERSARIOS -->
                            <template>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-3">
                                    <v-card class="borde-card" elevation="0">
                                        <v-card-title class="pb-3 pl-8 pt-8 pr-8">
                                            <v-col cols="12" xs="6" sm="7" md="8" lg="8" xl="9" class="pa-0">
                                                <v-icon class="icono" >mdi-calendar-today</v-icon>
                                                <!-- <i class="material-icons-outlined icono">&nbsp;insert_invitation</i> -->
                                                <label class="leyenda" for="">&nbsp;&nbsp;Aniversario</label>
                                            </v-col>
                                            <v-col cols="12" xs="6" sm="5" md="4" lg="4" xl="3" class="pa-0 dashboard-inputs2">
                                                <v-select
                                                    :items="meses_a"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    hide-details
                                                    v-model="mes_selecionado2"
                                                    outlined
                                                    dense
                                                    class="ma-0 pa-0"
                                                    append-icon="keyboard_arrow_down"
                                                ></v-select>
                                            </v-col>
                                        </v-card-title>
                                        <v-card-text class="pa-0">
                                            <simple-table
                                                :classes="tableClasses"
                                                :rows="itemsAniversario"
                                                :columns="headersAniversario"
                                                :perPage="[5, 25, 50, 100]"
                                                shortPagination
                                                :idTabla="'tablaAniversarios'"
                                            >
                                                <tbody slot="body" slot-scope="{ data }">
                                                    <tr :key="item.id" v-for="item in data" class="alto-tr">
                                                        <td>
                                                            <div class="tblPrincipal">
                                                                {{ item.nombre_completo }}
                                                            </div>
                                                            <div class="tblSecundario">{{ item.nombre_depto }}</div>
                                                        </td>
                                                        <td>
                                                            <div>
                                                                {{ item.fecha_aniversario }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </template>
                        </v-row>
                    </template>

                    <template v-else>
                        <v-row v-show="!overlay" >
                            <v-col cols="12" xs="12" sm="12" md="12" class="accionBtns py-0">
                                <v-row cols="12" class="float-right">
                                    <div class="botonAgregar">
                                        <v-btn 
                                            color="#2DB9D1" 
                                            class="btnDashboard elevation-0 "
                                            right
                                            rounded 
                                            :disabled="
                                                vuexShowCardColaboradores &&
                                                vuexShowCardCostoSocial &&
                                                vuexShowCardContratos &&
                                                vuexShowCardPagos
                                            "
                                            @click="cardAction('Quitar','restaurar')"
                                        >
                                            Restaurar tablero
                                        </v-btn>
                                    </div>
                                    <div class="botonAgregar">
                                        <v-menu v-model="modelMenu" bottom left :close-on-content-click="false" class="date-menu">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    color="#2DB9D1" 
                                                    class="btnDashboard elevation-0 float-right"
                                                    right
                                                    rounded 
                                                >
                                                    Fecha<v-icon medium class=" ml-2" dark>keyboard_arrow_down</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list >
                                                <v-list-item>
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                                                <div class="text-picker">Del:</div>
                                                                <VuetifyDatePicker
                                                                    outlined
                                                                    v-model="fecha_inicio"
                                                                    placeholder="dd/mm/aaaa"
                                                                    ref="FechaInicio"
                                                                    :valorDefecto="fecha_inicio"
                                                                    hide-details
                                                                    readonly
                                                                    :colorDias="'#2DB9D1'"
                                                                    :colorHeader="'#2DB9D1'"
                                                                />
                                                            </v-col>
                                                            <v-col cols="6" xs="6" sm="6" md="6" class="py-0">
                                                                <div class="text-picker">al:</div>
                                                                <VuetifyDatePicker
                                                                    outlined
                                                                    v-model="fecha_fin"
                                                                    placeholder="dd/mm/aaaa"
                                                                    ref="FechaFinal"
                                                                    :valorDefecto="fecha_fin"
                                                                    hide-details
                                                                    readonly
                                                                    :colorDias="'#2DB9D1'"
                                                                    :colorHeader="'#2DB9D1'"
                                                                />
                                                            </v-col>
                                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="d-flex justify-end">
                                                                <v-btn
                                                                    class="btn-enviar"
                                                                    rounded
                                                                    @click="enviarDatos()"
                                                                >
                                                                    enviar
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                    
                                    </div>
                                </v-row>
                            </v-col>

                            <v-col cols="12" class="px-0 py-5">
                                <draggable-dashboard 
                                    :father_fecha_inicio="fecha_inicio"
                                    :father_fecha_fin="fecha_fin"
                                >
                                </draggable-dashboard>
                            </v-col>
                        </v-row>
                    </template>
                </v-col>
				<template v-if="primer_login">
					<v-row justify-center>
						<v-dialog
							v-model="dialog_primer_login"
							persistent
							max-width="420px"
						>
							<template v-slot:activator="{ on }">
								<v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
							</template>
							<v-card>
								<ValidationObserver ref="form" v-slot="{ invalid }">
									<v-card-title class="padding-x50 pt-9 pb-8">
										<span class="titleModal" v-text="titulo_modal"></span>
									</v-card-title>
									<v-card-text class="card_texto">
										<v-container grid-list-md key="unique"> 
											<v-row class="align-center justify-center"><span id="span_cambio">Por políticas, es requerido cambiar la contraseña.</span></v-row>
											<v-row class="pt-4">
												<v-col cols="12" xs="12" sm="12" md="12" class="my-0 pa-0">
													<ValidationProvider v-slot="{ errors }" name="Contraseña" rules="required|verify_password">
														<v-text-field
															outlined
															label="Nueva contraseña"
															v-model="nuevo_password"
															id="nuevo_password"
															class="py-0"
															:append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
															@click:append="show1 = !show1"
															:type="show1 ? 'text' : 'password'"
															auto
															:error-messages="errors" 
                                                    		required
														/>
													</ValidationProvider>
												</v-col>
												<v-col cols="12" xs="12" sm="12" md="12" class="my-0 pa-0">
													<ValidationProvider v-slot="{ errors }" name="Confirmación" rules="required|verify_password">
														<v-text-field
															outlined
															label="Confirmar la contraseña"
															v-model="confirmacion"
															id="verificar_password"
															class="py-0"
															:append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
															@click:append="show2 = !show2"
															:type="show2 ? 'text' : 'password'"
															auto
															@keyup.enter="verificar('confirmacion')"
															:error-messages="errors" 
                                                    		required
														/>
													</ValidationProvider>
												</v-col>
												<div
													v-if="password_igual"
													class="v-stepper__label"
													style="color:#FF5454; padding-left: 3%; margin-top: -5%;"
												>
													Las contraseñas no son iguales, favor de verificar.
												</div>
											</v-row>
										</v-container>
									</v-card-text>
									<v-card-actions class="d-flex justify-end card_accion pt-0">
										<v-btn color="btnGuardar" @click="verificar()">Guardar</v-btn>
									</v-card-actions>
								</ValidationObserver>
							</v-card>
						</v-dialog>
					</v-row>
				</template>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import userApi from "@/api/usuarios";
import Notify from "@/plugins/notify";
import empleadosApi from "@/api/empleados";
import dashboardApi from "@/api/dashboard";
import draggable from "vuedraggable";
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import draggableDashboard from '@/components/dashboard/draggableFather';
import SimpleDataTable from "@/components/simpletable/Datatable.vue";
import Datatable2 from '@/components/g-datatable/Datatable.vue';
import queries2 from '@/queries/Timbres';
import apiIncidencias from '@/api/incidencias';
import empleadoApi from '@/api/empleados';

export default {
	components: {
            VuetifyDatePicker,
            'draggable-dashboard':draggableDashboard,
            draggable,
            "simple-table": SimpleDataTable,
            'data-table': Datatable2,
        },
	data() {
		return {
            overlay             : true,
            overlayDescarga     : false,
			fraseOverlay        : "Cargando",
			cardColaboradores   : true,
			cardContratos       : true,
			cardCostolaboral    : true,
			cardPercepciones    : true,
			cardPagos           : true,
			datosLogin	        : null,
            tipoDashboard       : null,
			rol			        : '',
			show1		        : false,
			show2		        : false,
            cliente_id			: null,
			empresa_id			: null,
			sucursal_id			: null,
			departamento_id		: null,
			usuario		        : {
				id                  : "",
				nombre              : "",
				apellido_paterno	: "",
				apellido_materno	: "",
				puesto				: "",
				email				: "",
				fecha_nacimiento	: "",
				telefono			: "",
				celular				: "",
				password			: "",
				primer_inicio_sesion: "",
				rol_id				: "",
			},
			primer_login		: false,
			titulo_modal		: "¿Cambiar contraseña?",
			dialog_primer_login	: false,
			nuevo_password		: "",
			confirmacion		: "",
			password_igual		: false,
			user_vuex	: {
				email	: "",
				password: "",
			},
			min_fecha : null,
			max_fecha : null,
            fecha_inicio : null,
            fecha_fin : null,
			dialog_grafica : false,
			mostrar_grafica : false,

            mes_selecionado: null,
            mes_selecionado2: null,
            meses_c: [
                { nombre: "Enero", id: 0 },
                { nombre: "Febrero", id: 1 },
                { nombre: "Marzo", id: 2 },
                { nombre: "Abril", id: 3 },
                { nombre: "Mayo", id: 4 },
                { nombre: "Junio", id: 5 },
                { nombre: "Julio", id: 6 },
                { nombre: "Agosto", id: 7 },
                { nombre: "Septiembre", id: 8 },
                { nombre: "Octubre", id: 9 },
                { nombre: "Noviembre", id: 10 },
                { nombre: "Diciembre", id: 11 },
            ],
            meses_a: [
                { nombre: "Enero", id: 0 },
                { nombre: "Febrero", id: 1 },
                { nombre: "Marzo", id: 2 },
                { nombre: "Abril", id: 3 },
                { nombre: "Mayo", id: 4 },
                { nombre: "Junio", id: 5 },
                { nombre: "Julio", id: 6 },
                { nombre: "Agosto", id: 7 },
                { nombre: "Septiembre", id: 8 },
                { nombre: "Octubre", id: 9 },
                { nombre: "Noviembre", id: 10 },
                { nombre: "Diciembre", id: 11 },
            ],
            tableClasses: {
                "t-head": {
                    "no-radius": true,
                    "titulo-first-child": true,
                },
            },
            headersCumpleanios: [
                { label: "Empleado", name: "nombre_completo", filterable: false },
                { label: "Cumpleaños", name: "cumpleanios", filterable: false },
            ],
            headersAniversario: [
                { label: "Empleado", name: "nombre_completo", filterable: false },
                { label: "Aniversario", name: "fecha_aniversario", filterable: false },
            ],
            itemsEmpleados  : [],
            itemsAniversario: [],
            filters : {AND:[]},
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            showTooltip     : false,
            showTooltip2    : false,
            itemsTimbres    : [],
            itemsIncidencias: [],
            modelMenu       : false,
		}
	},
	computed: {
    	...mapState("auth", ["datos", "user"]),
        ...mapState("dashboard", [
            "enviar", 
            "vuexShowCardColaboradores",
            "vuexShowCardCostoSocial",
            "vuexShowCardContratos",
            "vuexShowCardPagos",
        ])
	},
    watch: {
        mes_selecionado: function(val) {
            this.cambioMes(val);
        },
        mes_selecionado2: function(val) {
            this.cambioMesAniversario(val);
        },
        enviar:function(newVal, oldVal){
            if(newVal){
                console.log(newVal)
            }
        }
    },
	methods:{
		...mapActions("auth", ["logout", "update_password", "update_cliente"]),
        ...mapActions("dashboard", [
            "setEnviar", 
            "setDashboardContratos",
            "setDashboardCostoSocial", 
            "setDashboardTotalCostoSocial",
            "setVuexColaboradoresIngresos",
            "setVuexColaboradoresRotacion",
            "setVuexColaboradoresTotal",
            "setVuexColaboradoresTotalIngresos",
            "setVuexColaboradoresTotalRotacion",
            "setVuexColaboradoresShowIngresos",
            "setVuexColaboradoresShowRotacion",
            "setVuexPagosAxisX",
            "setVuexPagosSeries",
            "setVuexPagoList",
            "setVuexPagosTotal",
            "setVuexPagosShow",
            "setVuexShowCardColaboradores",
            "setVuexShowCardCostoSocial",
            "setVuexShowCardContratos",
            "setVuexShowCardPagos",

        ]),
		async inicio(){
			if (this.datos.primer_inicio_sesion == true) {
				this.primer_login = true;
				this.dialog_primer_login = true;
				setTimeout(function() {
					document.getElementById("nuevo_password").focus();
				}, 250);
			}

			let empleado_id = null;
            if (this.rol == "admin") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
            } else if (this.rol == "admin-empresa") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
            } else if (this.rol == "admin-sucursal") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
            } else if (this.rol == "admin-depto") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            } else if (this.rol == "empleado") {
                this.cliente_id = parseInt(this.datosLogin.cliente_id, 10);
                this.empresa_id = parseInt(this.datosLogin.empresa_id, 10);
                this.sucursal_id = parseInt(this.datosLogin.sucursal_id, 10);
                this.departamento_id = parseInt(this.datosLogin.departamento_id, 10);
            }

            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
            var ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();
            this.min_fecha = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            this.max_fecha =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

			let parametrosDashboard = {};
            if (this.rol != "admin-depto") {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    departamento_id: this.departamento_id,
                    fecha_inicio: this.fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            } 
            else {
                parametrosDashboard = {
                    rol_name    : this.datosLogin.rol.name,
                    usuario_id  : this.datosLogin.id,
                    cliente_id  : this.cliente_id,
                    empresa_id  : this.empresa_id,
                    sucursal_id : this.sucursal_id,
                    fecha_inicio: this.fecha_inicio,//'2020-01-01',//inicio,
                    fecha_final : this.fecha_fin,//'2022-12-12',//final,
                    rol_name    : this.rol,
                };
            }

            let arrayDatos = [];

            this.setVuexColaboradoresIngresos(arrayDatos)
            this.setVuexColaboradoresRotacion(arrayDatos)
            this.setVuexColaboradoresTotal(0)
            this.setVuexColaboradoresTotalIngresos(0)
            this.setVuexColaboradoresTotalRotacion(0)
            this.setVuexColaboradoresShowIngresos(false)
            this.setVuexColaboradoresShowRotacion(false)


            this.setVuexPagosAxisX(arrayDatos) //nombres
            this.setVuexPagosSeries(arrayDatos)//datos
            this.setVuexPagoList(arrayDatos)
            this.setVuexPagosTotal("0.00")
            this.setVuexPagosShow(false)

            this.modelMenu = false;
            this.overlay = true;
			if (this.rol != "root" && this.rol!="empleado") {
                this.mes_selecionado = date.getMonth();
                this.mes_selecionado2 = date.getMonth();
                empleadosApi.getDashboardNominas(parametrosDashboard).then(async(response) => {

                    //Componente Contratos
                    this.setDashboardContratos(response.contratos);

                    //Componente Costo Social
                    this.setDashboardCostoSocial(response.percepciones.costosocial);
                    this.setDashboardTotalCostoSocial(response.percepciones.totalCostosocial);



                    //***************************************
                    //***************************************
                    //        Componente Colaboradores
                    //***************************************
                    //***************************************

                    let arrayIngresos = []
                    let arrayRotacion = []
                    this.setVuexColaboradoresIngresos(arrayIngresos)
                    this.setVuexColaboradoresRotacion(arrayRotacion)
                    this.setVuexColaboradoresTotal(response.empleados.total)
                    this.setVuexColaboradoresTotalIngresos(response.empleados.nuevos)
                    this.setVuexColaboradoresTotalRotacion(response.empleados.bajas)
                    

                    let sin_ingresos = 0;
                    let format_desglose = response.empleados.desglose;
                    await format_desglose.forEach((element) => {
                        if (element["total"] == 0) {
                            sin_ingresos++;
                            if (sin_ingresos == format_desglose.length) {
                                let b = {
                                    value: 1,
                                    name: "Sin ingresos",
                                };
                                arrayIngresos.push(b);
                            }
                        }
                        if (element["total"] != 0) {
                            let b = {
                                value: element["total"],
                                name: element["nombre"],
                            };
                            arrayIngresos.push(b);
                        }

                        this.setVuexColaboradoresIngresos(arrayIngresos)
                        this.setVuexColaboradoresShowIngresos(true)
                    });
                    let sin_rotacion = 0;
                    let format_rotacion = response.empleados.rotacion;
                    if (this.rol == "admin" || "admin-empresa" || "admin-sucursal") {
                        if (format_rotacion.length == 0 || format_rotacion == undefined) {

                        } 
                        else {
                            await format_rotacion.forEach((element) => {
                                let b = {
                                    value: element["total"],
                                    name: element["nombre"],
                                };
                                arrayRotacion.push(b);
                            });
                            this.setVuexColaboradoresRotacion(arrayRotacion)
                            this.setVuexColaboradoresShowRotacion(true)

                        }
                    }


                    //***************************************
                    //***************************************
                    //        Fin Componente Colaboradores
                    //***************************************
                    //***************************************




                    

                    //**********************************
                    //**********************************
                    //        Componente Pagos
                    //**********************************
                    //**********************************
                    let arrayPagosAxisX = []
                    let arrayPagosSeries = []
                    let arrayPagoList=[]
                    this.setVuexPagosAxisX(arrayPagosAxisX) //nombres
                    this.setVuexPagosSeries(arrayPagosSeries)//datos
                    this.setVuexPagoList(arrayPagoList)
                    this.setVuexPagosTotal("0.00")

                    if(response.percepciones.percepciones.length>0){
                        this.setVuexPagosShow(true)
                        this.setVuexPagosTotal(response.percepciones.totalPercepcion)
                        await response.percepciones.percepciones.forEach(element => {
                            if(parseFloat(element.monto)>0){
                                arrayPagoList.push(element)
                                arrayPagosAxisX.push(element.nombre)
                                arrayPagosSeries.push(parseFloat(element.monto.replace(',', '')))
                            }
                        });
                        
                        this.setVuexPagosAxisX(arrayPagosAxisX) //nombres
                        this.setVuexPagosSeries(arrayPagosSeries)//datos
                        this.setVuexPagoList(arrayPagoList) 
                    }
                    else{
                        this.setVuexPagosShow(false)
                    }
                    //************************************
                    //************************************
                    //        Fin Componente Pagos
                    //************************************
                    //************************************
                    this.overlay = false;

                })
                .catch(err => {
                    this.overlay = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });
            }
            else if(this.rol == "empleado"){
                this.mes_selecionado = date.getMonth();
                this.mes_selecionado2 = date.getMonth();  
                //this.overlay = false;
                setTimeout(()=>{
                    this.overlay = false;
                },2000);
            }
		
		},
        
        cambioMes() {
            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), this.mes_selecionado, 1);
            var ultimoDia = new Date(date.getFullYear(), this.mes_selecionado + 1, 0);
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

            let paramCumpleanios = {
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                cliente_id: this.datosLogin.cliente_id,
                empresa_id: this.datosLogin.empresa_id,
                sucursal_id: this.datosLogin.sucursal_id,
                departamento_id: this.datosLogin.departamento_id,
                min_fecha: inicio,
                max_fecha: final,
                rol_name: this.datosLogin.rol.name,
            };

            if (this.rol != "root") {
                empleadosApi.cumpleaniosEmpleado(paramCumpleanios).then((response) => {
                    let format = response.cumpleanios;
                    format.forEach((element) => {
                        element["nombre_completo"] = element["nombre"] + " " + element["apellido_paterno"];
                        let fnacimiento = element["fecha_nacimiento"].split("-");
                        element["cumpleanios"] =
                            fnacimiento[2] +
                            " / " +
                            fnacimiento[1] +
                            " / " +
                            fnacimiento[0];
                    });
                    this.itemsEmpleados = format;
                });
            }
        },
        cambioMesAniversario() {
            var date = new Date();
            let inicio = null;
            let final = null;
            let self = this;
            var primerDia = new Date(date.getFullYear(), this.mes_selecionado2, 1);
            var ultimoDia = new Date(
                date.getFullYear(),
                this.mes_selecionado2 + 1,
                0
            );
            var mes_verificado = null;

            var anio = date.getFullYear();
            var mes = primerDia.getMonth() + 1;
            if (
                mes == 1 ||
                mes == 2 ||
                mes == 3 ||
                mes == 4 ||
                mes == 5 ||
                mes == 6 ||
                mes == 7 ||
                mes == 8 ||
                mes == 9
            ) {
                mes_verificado = "0" + mes;
            } else {
                mes_verificado = "" + mes;
            }
            inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
            final =
                ultimoDia.getFullYear() +
                "-" +
                mes_verificado +
                "-" +
                ultimoDia.getDate();

            let paramCumpleanios = {
                rol_name    : this.datosLogin.rol.name,
                usuario_id  : this.datosLogin.id,
                cliente_id  : this.datosLogin.cliente_id,
                empresa_id  : this.datosLogin.empresa_id,
                sucursal_id : this.datosLogin.sucursal_id,
                departamento_id: this.datosLogin.departamento_id,
                min_fecha   : inicio,
                max_fecha   : final,
                rol_name    : this.datosLogin.rol.name,
            };

            if (this.rol != "root") {
                empleadosApi.cumpleaniosEmpleado(paramCumpleanios).then((response) => {
                    let format_aniversario = response.aniversarios;
                    format_aniversario.forEach((element) => {
                        element["nombre_completo"] = element["nombre"] + " " + element["apellido_paterno"];
                        let faniversario = element["fecha_ingreso"].split("-");
                        element["fecha_aniversario"] =
                            faniversario[2] +
                            " / " +
                            faniversario[1] +
                            " / " +
                            faniversario[0];
                    });
                    this.itemsAniversario = format_aniversario;
                });
            }
        },

		cardAction(type,card){
			if(type == "Quitar"){
				switch(card){
					case 1:
						this.cardColaboradores = false;
						break;
					case 2:
						this.cardContratos = false;
						break;
					case 3:
						this.cardCostolaboral = false;
						break;
					case 4:
						this.cardPercepciones = false;
						break;
					case 5:
						this.cardPagos = false;
						break;	
					case 'restaurar':
						//this.cardColaboradores = true;
						//this.cardContratos = true;
						//this.cardCostolaboral = true;
                        //this.cardPercepciones = true;
						//this.cardPagos = true;
                        this.setVuexShowCardColaboradores(true)
                        this.setVuexShowCardCostoSocial(true)
                        this.setVuexShowCardContratos(true)
                        this.setVuexShowCardPagos(true)
						break;
				}
					
				
			}else if(type == "Mover"){

			}
		},
		async update() {
			this.llena_datos();
			await this.update_password(this.user_vuex);
			await userApi.updatePassword(this.usuario).then((response) => {
					userApi.getUsuario(this.usuario.id).then((response) => this.update_cliente(response))
					.catch((err) => console.log(err));
					this.cerrarModal();
					this.respuesta_ok("cliker® ya esta listo.");
				})
				.catch((err) => this.respuesta_error(err));
		},
		verificar() {
			this.$refs.form.validate().then(success => {
				if(!success) {
                    return;
                }
				if (success) {
					if (this.confirmacion == this.nuevo_password) {
						this.password_igual = false;
						this.update();
					} 
					else {
						this.password_igual = true;
					}
				}
			});
		},
		llena_datos() {
			this.usuario.id 				= this.datos.id;
			this.usuario.nombre 			= this.datos.nombre;
			this.usuario.apellido_paterno 	= this.datos.apellido_paterno;
			this.usuario.apellido_materno 	= this.datos.apellido_materno;
			this.usuario.puesto 			= this.datos.puesto;
			this.usuario.email 				= this.datos.email;
			this.usuario.telefono 			= this.datos.telefono;
			this.usuario.celular 			= this.datos.celular;
			this.rol_nombre 				= this.datos.rol.display_name;
			this.usuario.rol_id				= this.datos.rol.id;
			this.usuario.primer_inicio_sesion = false;
			this.usuario.password 			= this.confirmacion;
			this.user_vuex.email 			= this.usuario.email;
			this.user_vuex.password 		= this.confirmacion;
			this.usuario.fecha_nacimiento 	= "";

			if (this.datos.fecha_nacimiento != null) {
				this.usuario.fecha_nacimiento = this.datos.fecha_nacimiento.replace(
				/^(\d{4})-(\d{2})-(\d{2})$/g,
				"$3/$2/$1"
				);
			}
		},
		cerrarModal() {
			this.dialog_grafica = false;
			this.dialog_primer_login = false;
			this.primer_login = false;
			this.usuario = {};
		},
		respuesta_error(err) {

			/* if(typeof err.response.data.error === 'string'){
				Notify.ErrorToast(err.response.data.error);
			}else{
				let error = Object.values(err.response.data.error);
				let leyenda = ``;

				for (var i = 0; i < error.length; i++) {
					leyenda+= `* ` + error[i] + `\n`;
				}

				Notify.ErrorToast(leyenda);
			} */
			Notify.Alert("Error", err.response.data.error);
		},
		respuesta_ok(msj = "") {
			this.cerrarModal();
			this.dialog = false;
			Notify.Success("Operación exitosa", msj);
		},

		
        disabledLogro(itemLogro){
            let datosEmpleado = this.$session.get('empleado');
            let logro = datosEmpleado.objRankig.find(item => item == itemLogro)
            switch (logro) {
                case "Antigüedad":
                    return '';
                    break;
                case "Clima laboral":
                    return '';
                    break;
                case "Proyectos":
                    return '';
                    break;
                case "Puntualidad":
                    return '';
                    break;
                case "Capacitación":
                    return '';
                    break;
                case undefined:
                    return 'disabledLogro';
                    break;
                default:
                    return 'disabledLogro';
                    break;
            }
        },
         getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
        },
        irIrncidencias(){
            this.$router.push('/incidencias');
        },
        irDetalle(){
            this.$session.remove("detalleEmpleado");
            this.$router.push("/kardex/detalleEmpleado");
        },
        descargarTimbre(item = null,documento = true, tipo){
            this.overlayDescarga = true;
            this.fraseOverlay = "Descargando";
            let self = this;
            if(documento){
                const FileDownload = require('js-file-download');
                const axios = require('axios');
                let url = 'incidencias/download';
                var nombre_archivo = item.url.split("/"); 
                nombre_archivo = item.nombre + "_" + nombre_archivo[3];
                let parametros = { url_archivo: item.url }
                try {
                    axios({
                    method: 'post',
                    url: url,
                    responseType: 'blob',
                    data: parametros,
                    })
                    .then(function (response) {
                        self.overlayDescarga = false;
                        FileDownload(response.data,nombre_archivo);
                        self.fraseOverlay = "Cargando";
                    });
                } catch (error) {
                    self.overlayDescarga = false;
                    self.fraseOverlay = "Cargando";
                    console.log(error);
                }
            }else{
                const FileDownload = require('js-file-download');
                const axios = require('axios');
                let url = 'nominas-timbres/descargar';
                let fecha = item.timbrado.split(' ');
                var nombre_archivo = "Recibo_de_nomina_"+fecha[0]+"."+tipo; 
                let parametros = {nomina_id:[item.id],tipo}
                try {
                    axios({
                    method: 'post',
                    url: url,
                    responseType: 'blob',
                    data: parametros,
                    })
                    .then(function (response) {
                        FileDownload(response.data,nombre_archivo);
                        self.overlayDescarga = false;
                        self.fraseOverlay = "Cargando";
                    
                    });
                } catch (error) {
                    self.overlayDescarga = false
                    console.log(error);
                    self.fraseOverlay = "Cargando";
                }
            }
        },
        getIncidencias(){
            let param = {
                rol_name        : this.rol,
                usuario_id      : this.datosLogin.id,
                activo          : true,
                paginate        : true,
                include_padres  : true,
                cliente_id      : null,
                empresa_id      : null,
                sucursal_id     : null,
                departamento_id : null,
                order_by        : 'fecha_final',
                empleado_id     : this.$session.get("empleado").id
            };

            apiIncidencias.getIncidencias(param).then((response) => {
                this.itemsIncidencias = response.data;
            })
            .catch((error) => {
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },
        getColorTextoStatus(statusIncidencia) {
            if (statusIncidencia == "estatus_aprobado") return "#15D511";
            if (statusIncidencia == "estatus_rechazado") return "#EE1616";
            if (statusIncidencia == "estatus_pendiente") return "#FF9B05";
            if (statusIncidencia == "estatus_revision") return "#F8690E";
        },
        async session_empleado(){
            let usuario = this.$session.get('usuario');
            let param = {
                rol_name:usuario.rol.name, 
                usuario_id:usuario.id, 
                activo: true, 
                empleado_id: usuario.empleado_id,
                correo: usuario.email
            }
            await empleadoApi.getEmpleados(param).then((response) => {
                let data = response.data[0];
                this.$session.set('empleado',data);
            })
            .catch(err => {
                console.log(err); 
            });
        },
        enviarDatos(){
            this.inicio()
        }
	},
	async created(){
		this.datosLogin = this.$session.get('usuario');
		this.rol = this.datosLogin.rol.name;
        this.tipoDashboard = this.datosLogin.tipo_dashboard;


        let date = new Date();
        let primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
        let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        let mes_verificado = null;

        let mes = primerDia.getMonth() + 1;
        if (
            mes == 1 ||
            mes == 2 ||
            mes == 3 ||
            mes == 4 ||
            mes == 5 ||
            mes == 6 ||
            mes == 7 ||
            mes == 8 ||
            mes == 9
        ) {
            mes_verificado = "0" + mes;
        } else {
            mes_verificado = "" + mes;
        }
        this.fecha_inicio = primerDia.getFullYear() + "-" + mes_verificado + "-01";
        this.fecha_fin = ultimoDia.getFullYear() + "-" + mes_verificado + "-" + ultimoDia.getDate();
		await this.inicio();        
	}, 
	async mounted() {
        if(this.rol === "empleado"){
            await this.session_empleado()
            let datosEmpleado = this.$session.get('empleado');
            this.filters = {AND:[
                { column: 'EMPLEADO_ID', value: datosEmpleado.id },
                { column:"ERRORES", operator:"IS_NULL"},
                { column:"CANCELADO", value:false}
            ]};
            this.$apollo.queries.timbres.skip = false;

            this.getIncidencias();
        }
		/* setTimeout(()=>{
		    this.overlay = false;
		},1000); */
	},
    updated() {    
        let tablaAniversarios = document.querySelector('#tablaAniversarios');
        let tablaCumpleanios = document.querySelector('#tablaCumpleanios');

        if(tablaAniversarios){
            document.querySelector('#tablaAniversarios tbody').style.minWidth = "400px";
        }

        if(tablaCumpleanios){
            document.querySelector('#tablaCumpleanios tbody').style.minWidth = "400px";
        }
    },
    apollo: {
        timbres: {
            query       : queries2.timbresTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereConditions : this.filters,
                    numberItems : 5,
                    numberPage  : 1,
                    fieldOrder  : 'id',
                    Order       : 'DESC',
                }
            },
            skip(){
                return true;
            },
            result({loading, error}) {
                this.itemsTimbres  = this.timbres.data;
               
            },
        },
    },
}
</script>

<style scoped>
    .accionBtns{
        margin-top:-20px;
        padding-right:25px;
    }

    .btnDashboard.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        height: 38px !important;
        background: #58C5D8;
        border-radius: 20px;
        text-transform:none;
    }

    .btnDashboard.theme--light.v-btn:hover {
        background: #2DB9D1 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        text-transform:none;
    }
	.center{
		padding-left:32px;
		padding-right:32px;
		margin-left: auto;
		margin-right: auto;
	}
	.margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}
	.backgroundDash{
		height: 80%;
		width: 80%;
		margin:5%;
		padding: 5%;
		position: fixed;
		background: url("/static/bg/cliker.png");
		background-repeat: no-repeat;
		background-size:50%;
		background-position: center center;
	}
	#span_cambio {
		font-family: "Montserrat";
		font-style: normal;
		font-size: 11px;
		color: #FF5454;
		font-weight: bold;		
	}
	.padding-x50{
		padding-left: 50px !important;
		padding-right: 50px !important;
	}
    .v-menu__content{
        border-radius: 15px;
		width: 450px;
        margin-top: 48px !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
    .icono {
        color: #2DB9D1;
    }
    .leyenda {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 29px;
        color: rgba(16, 28, 90);
    }
    #tablaCumpleanios tbody{
        min-width: 400px ;
    }
    #tablaAniversarios tbody{
        min-width: 400px ;
    }
    .textMisLogros {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 22.3815px;
        line-height: 27px;
        color: #444975;
    }

    .disabledLogro {
        pointer-events: none;
        opacity: 0.4;
    }
    .linkTooltip{
        color:#8AE0EF;
        text-decoration: underline #8AE0EF;
        cursor:pointer;
    }
    .emptyItems{
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #c5c5c5;
    }
    .textCard{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
    }
    .textChip{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    .colorMdiFilePdf:hover{
        color: red !important;
    }
    .colorMdiFileXml:hover{
        color:green !important;
    }
    .colorMdiZip:hover{
        color:blue !important;
    }
    .btn-enviar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #FFFFFF;
        text-transform: capitalize;
        transition: 0.3s;
        background: #58C5D8 !important;
    }
    .text-picker{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: #828282;
    }

</style>